import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** action_dispatch_uploaded_file */
  File: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
};


export type Admin = {
  __typename?: 'Admin';
  email: Scalars['String'];
  id: Scalars['ID'];
  role: AdminRole;
};

export enum AdminRole {
  SuperAdmin = 'super_admin',
  RestaurantAdmin = 'restaurant_admin',
  Admin = 'admin'
}

export type App = {
  __typename?: 'App';
  id: Scalars['ID'];
  operatingCountries: Array<Country>;
};

/** Autogenerated return type of ArchiveDiscountCode. */
export type ArchiveDiscountCodePayload = {
  __typename?: 'ArchiveDiscountCodePayload';
  discountCode?: Maybe<DiscountCode>;
  errors?: Maybe<Array<Error>>;
};

export type CabinetSlot = {
  __typename?: 'CabinetSlot';
  id: Scalars['ID'];
  number: Scalars['Int'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  popular: Scalars['Boolean'];
};

/** Autogenerated return type of CompleteOrder. */
export type CompleteOrderPayload = {
  __typename?: 'CompleteOrderPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Consumer = {
  __typename?: 'Consumer';
  createdAt: Scalars['ISO8601DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastOrderAt?: Maybe<Scalars['ISO8601DateTime']>;
  name?: Maybe<Scalars['String']>;
  newsletterSubscribed: Scalars['Boolean'];
  ordersCount: Scalars['Int'];
  ordersCountLastOrder?: Maybe<Scalars['Int']>;
  phoneNumber: Scalars['String'];
};

export type Consumers = {
  __typename?: 'Consumers';
  consumers?: Maybe<Array<Consumer>>;
  id: Scalars['ID'];
  pageLimit: Scalars['Int'];
  total: Scalars['Int'];
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String'];
  country: Scalars['String'];
  currency: Currency;
  id: Scalars['ID'];
};

/** Autogenerated return type of CreateCategory. */
export type CreateCategoryPayload = {
  __typename?: 'CreateCategoryPayload';
  category?: Maybe<Category>;
  errors?: Maybe<Array<Error>>;
};

export type CreateDiscountCodeInput = {
  name: Scalars['String'];
  code: Scalars['String'];
  discountVariant: DiscountVariant;
  deliveryOptions: Array<DeliveryOption>;
  paymentMethods: Array<PaymentMethod>;
  amount: Scalars['Float'];
  shouldAutoIncludeNewStore: Scalars['Boolean'];
  maximumDiscountAmount: Scalars['Float'];
  minimumAmountToApply: Scalars['Float'];
  dailyLimit?: Maybe<Scalars['Int']>;
  totalUsageLimit: Scalars['Int'];
  platformFundedPercentage: Scalars['Float'];
  validFrom: Scalars['ISO8601DateTime'];
  validUntil: Scalars['ISO8601DateTime'];
  storeDiscountCodes?: Maybe<Array<StoreDiscountCodesInput>>;
};

/** Autogenerated return type of CreateDiscountCode. */
export type CreateDiscountCodePayload = {
  __typename?: 'CreateDiscountCodePayload';
  discountCode?: Maybe<DiscountCode>;
  errors?: Maybe<Array<Error>>;
};

export type CreateMixinPromoInput = {
  outletIds?: Maybe<Array<Scalars['ID']>>;
  storesCount: Scalars['Int'];
  amount: Scalars['Int'];
  category: MixinPromoCategory;
  minSpend: Scalars['Int'];
  capped: Scalars['Int'];
};

/** Autogenerated return type of CreateMixinPromo. */
export type CreateMixinPromoPayload = {
  __typename?: 'CreateMixinPromoPayload';
  errors?: Maybe<Array<Error>>;
  mixinPromo?: Maybe<MixinPromo>;
};

export type CreateOutletInput = {
  name: Scalars['String'];
  address: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  open: Scalars['String'];
  close: Scalars['String'];
  hide?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['File']>;
  slides?: Maybe<Array<Scalars['File']>>;
  slidesUrls?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of CreateOutlet. */
export type CreateOutletPayload = {
  __typename?: 'CreateOutletPayload';
  errors?: Maybe<Array<Error>>;
  outlet?: Maybe<Outlet>;
};

/** Autogenerated return type of CreatePaymentProof. */
export type CreatePaymentProofPayload = {
  __typename?: 'CreatePaymentProofPayload';
  errors?: Maybe<Array<Error>>;
  purchaseOrder?: Maybe<SupplierPurchaseOrder>;
};

export type CreateRestaurantAdminInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  isCashier: Scalars['Boolean'];
  storeIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of CreateRestaurantAdmin. */
export type CreateRestaurantAdminPayload = {
  __typename?: 'CreateRestaurantAdminPayload';
  errors?: Maybe<Array<Error>>;
  restaurantAdmin?: Maybe<RestaurantUser>;
};

export type CreateRestaurantInput = {
  outletId: Scalars['ID'];
  name: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  taxPercentage: Scalars['Float'];
  counterFeePercentage: Scalars['Float'];
  techFeePercentage: Scalars['Float'];
  revenueSharePercentage: Scalars['Float'];
};

/** Autogenerated return type of CreateRestaurant. */
export type CreateRestaurantPayload = {
  __typename?: 'CreateRestaurantPayload';
  errors?: Maybe<Array<Error>>;
  restaurant?: Maybe<Restaurant>;
};

export type CreateRestaurantUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  restaurantId: Scalars['ID'];
  isCashier: Scalars['Boolean'];
  role: RestaurantUserRole;
  storeIds?: Maybe<Array<Scalars['ID']>>;
  isBanned?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreateRestaurantUser. */
export type CreateRestaurantUserPayload = {
  __typename?: 'CreateRestaurantUserPayload';
  errors?: Maybe<Array<Error>>;
  restaurantUser?: Maybe<RestaurantUser>;
};

export type CreateSupplierInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
};

/** Autogenerated return type of CreateSupplier. */
export type CreateSupplierPayload = {
  __typename?: 'CreateSupplierPayload';
  errors?: Maybe<Array<Error>>;
  supplier?: Maybe<Supplier>;
};

export type Currency = {
  __typename?: 'Currency';
  code: Scalars['String'];
  id: Scalars['ID'];
  paymentMethods: Array<PaymentMethod>;
  symbol: Scalars['String'];
};

export type DateRanges = {
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of DeleteMainPopUpImage. */
export type DeleteMainPopUpImagePayload = {
  __typename?: 'DeleteMainPopUpImagePayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteOutletPopUpImage. */
export type DeleteOutletPopUpImagePayload = {
  __typename?: 'DeleteOutletPopUpImagePayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum DeliveryOption {
  Delivery = 'delivery',
  Pickup = 'pickup',
  DineIn = 'dine_in'
}

export type DiscountCode = {
  __typename?: 'DiscountCode';
  amount: Scalars['Float'];
  code: Scalars['String'];
  dailyLimit?: Maybe<Scalars['Int']>;
  deliveryOptions: Array<DeliveryOption>;
  discountVariant: DiscountVariant;
  id: Scalars['ID'];
  maximumDiscountAmount: Scalars['Float'];
  minimumAmountToApply: Scalars['Float'];
  name: Scalars['String'];
  paymentMethods: Array<PaymentMethod>;
  platformFundedPercentage: Scalars['Float'];
  remains: Scalars['Int'];
  shouldAutoIncludeNewStore: Scalars['Boolean'];
  state: DiscountCodeState;
  storeDiscountCodes?: Maybe<Array<StoreDiscountCodes>>;
  totalUsageLimit: Scalars['Int'];
  validFrom: Scalars['ISO8601DateTime'];
  validUntil: Scalars['ISO8601DateTime'];
};

export enum DiscountCodeState {
  Disabled = 'disabled',
  Expired = 'expired',
  Scheduled = 'scheduled',
  Active = 'active',
  Archived = 'archived'
}

export enum DiscountVariant {
  Fixed = 'fixed',
  Percentage = 'percentage'
}

/** Autogenerated return type of EmailResetPasswordUrl. */
export type EmailResetPasswordUrlPayload = {
  __typename?: 'EmailResetPasswordUrlPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Error = {
  __typename?: 'Error';
  detail: Scalars['String'];
  metadata?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};



export enum Integration {
  Coox = 'coox',
  Grabfood = 'grabfood',
  Foodpanda = 'foodpanda',
  Shopeefood = 'shopeefood'
}

export type KitchenSalesBreakdown = {
  __typename?: 'KitchenSalesBreakdown';
  id: Scalars['ID'];
  kitchenName: Scalars['String'];
  kitchenNumber?: Maybe<Scalars['String']>;
  sales?: Maybe<Array<KitchenSalesBreakdownItem>>;
  totalCounterFee: Scalars['Float'];
  totalPlatformFee: Scalars['Float'];
  totalRevenue: Scalars['Float'];
  totalRevenueShare: Scalars['Float'];
};

export type KitchenSalesBreakdownItem = {
  __typename?: 'KitchenSalesBreakdownItem';
  brand: Scalars['String'];
  brandName: Scalars['String'];
  counterFee: Scalars['Float'];
  id: Scalars['ID'];
  platformFee: Scalars['Float'];
  revenue: Scalars['Float'];
  revenueShare: Scalars['Float'];
};

export type KitchenSalesBreakdownTotal = {
  __typename?: 'KitchenSalesBreakdownTotal';
  id: Scalars['ID'];
  kitchenSales?: Maybe<Array<KitchenSalesBreakdown>>;
  totalCounterFee: Scalars['Float'];
  totalPlatformFee: Scalars['Float'];
  totalRevenue: Scalars['Float'];
  totalRevenueShare: Scalars['Float'];
};

export type KitchenSalesComparison = {
  __typename?: 'KitchenSalesComparison';
  afterName?: Maybe<Scalars['String']>;
  afterValue?: Maybe<Scalars['Float']>;
  beforeName?: Maybe<Scalars['String']>;
  beforeValue?: Maybe<Scalars['Float']>;
  categoryName: Scalars['String'];
  id: Scalars['ID'];
  restaurantId: Scalars['ID'];
};

export type KitchenSalesComparisonTotal = {
  __typename?: 'KitchenSalesComparisonTotal';
  id: Scalars['ID'];
  salesComparisonData?: Maybe<Array<KitchenSalesComparison>>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

/** Autogenerated return type of Login. */
export type LoginPayload = {
  __typename?: 'LoginPayload';
  errors?: Maybe<Array<Error>>;
  jwt?: Maybe<Scalars['String']>;
  me?: Maybe<Admin>;
};

export type MerchantSales = {
  __typename?: 'MerchantSales';
  id: Scalars['ID'];
  pageLimit: Scalars['Int'];
  sales: Array<StoreOrder>;
};

export type MerchantSalesTotal = {
  __typename?: 'MerchantSalesTotal';
  id: Scalars['ID'];
  totalCount: Scalars['Int'];
  totalCounterFee: Scalars['Float'];
  totalExternalFee: Scalars['Float'];
  totalMerchantFundedPromo: Scalars['Float'];
  totalNetIncome: Scalars['Float'];
  totalOriginalPrice: Scalars['Float'];
  totalPlatformFee: Scalars['Float'];
  totalPlatformFundedPromo: Scalars['Float'];
  totalRevenue: Scalars['Float'];
  totalRevenueShare: Scalars['Float'];
  totalTax: Scalars['Float'];
};

export type MixOrder = {
  __typename?: 'MixOrder';
  acceptedTime?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  integration?: Maybe<Scalars['String']>;
  orderCode: Scalars['String'];
  orderId: Scalars['String'];
  originalPrice: Scalars['Float'];
  state: OrderState;
  store: Store;
  storeOrders?: Maybe<Array<StoreOrder>>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type MixinPromo = {
  __typename?: 'MixinPromo';
  amount: Scalars['Int'];
  capped: Scalars['Int'];
  category: MixinPromoCategory;
  id: Scalars['ID'];
  minSpend: Scalars['Int'];
  outletIds?: Maybe<Array<Scalars['String']>>;
  outletNames?: Maybe<Array<Scalars['String']>>;
  percentage: Scalars['Float'];
  storesCount: Scalars['Int'];
};

export enum MixinPromoCategory {
  Percentage = 'percentage',
  Flat = 'flat'
}

export type Mutation = {
  __typename?: 'Mutation';
  archiveDiscountCode?: Maybe<ArchiveDiscountCodePayload>;
  completeOrder?: Maybe<CompleteOrderPayload>;
  createCategory?: Maybe<CreateCategoryPayload>;
  createDiscountCode?: Maybe<CreateDiscountCodePayload>;
  createMixinPromo?: Maybe<CreateMixinPromoPayload>;
  createOutlet?: Maybe<CreateOutletPayload>;
  createRestaurant?: Maybe<CreateRestaurantPayload>;
  createRestaurantAdmin?: Maybe<CreateRestaurantAdminPayload>;
  createRestaurantUser?: Maybe<CreateRestaurantUserPayload>;
  createSupplier?: Maybe<CreateSupplierPayload>;
  createSupplierPaymentProof?: Maybe<CreatePaymentProofPayload>;
  deleteMainPopUpImage?: Maybe<DeleteMainPopUpImagePayload>;
  deleteOutletPopUpImage?: Maybe<DeleteOutletPopUpImagePayload>;
  emailResetPasswordUrl?: Maybe<EmailResetPasswordUrlPayload>;
  login?: Maybe<LoginPayload>;
  pushNotification?: Maybe<PushNotificationPayload>;
  removeMixinPromo?: Maybe<RemoveMixinPromoPayload>;
  removeRestaurantUser?: Maybe<RemoveRestaurantUserPayload>;
  removeSupplierFinancing?: Maybe<RemoveFinancingPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  toggleDiscountCode?: Maybe<ToggleDiscountCodePayload>;
  toggleEnableMailing?: Maybe<ToggleEnableMailingPayload>;
  toggleFirstOrderPromo?: Maybe<ToggleFirstOrderPromoPayload>;
  updateBookingViewingEmails?: Maybe<UpdateBookingViewingEmailsPayload>;
  updateConsumerAnnouncement?: Maybe<UpdateConsumerAnnouncementPayload>;
  updateDiscountCode?: Maybe<UpdateDiscountCodePayload>;
  updateKitchenAppVersion?: Maybe<UpdateKitchenAppVersionPayload>;
  updateMainPopUpImage?: Maybe<UpdateMainPopUpImagePayload>;
  updateOperatingCountries?: Maybe<UpdateOperatingCountriesPayload>;
  updateOutlet?: Maybe<UpdateOutletPayload>;
  updateOutletPopUpImage?: Maybe<UpdateOutletPopUpImagePayload>;
  updateRestaurant?: Maybe<UpdateRestaurantPayload>;
  updateRestaurantAdmin?: Maybe<UpdateRestaurantAdminPayload>;
  updateRestaurantUser?: Maybe<UpdateRestaurantUserPayload>;
  updateStoreAutoAssignEmails?: Maybe<UpdateStoreAutoAssignEmailsPayload>;
  updateSupplyBnpl?: Maybe<UpdateSupplyBnplPayload>;
};


export type MutationArchiveDiscountCodeArgs = {
  id: Scalars['ID'];
};


export type MutationCompleteOrderArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCategoryArgs = {
  name: Scalars['String'];
};


export type MutationCreateDiscountCodeArgs = {
  input: CreateDiscountCodeInput;
};


export type MutationCreateMixinPromoArgs = {
  input: CreateMixinPromoInput;
};


export type MutationCreateOutletArgs = {
  input: CreateOutletInput;
};


export type MutationCreateRestaurantArgs = {
  input: CreateRestaurantInput;
};


export type MutationCreateRestaurantAdminArgs = {
  input: CreateRestaurantAdminInput;
};


export type MutationCreateRestaurantUserArgs = {
  input: CreateRestaurantUserInput;
};


export type MutationCreateSupplierArgs = {
  input: CreateSupplierInput;
};


export type MutationCreateSupplierPaymentProofArgs = {
  file: Scalars['File'];
  purchaseOrderId: Scalars['ID'];
};


export type MutationDeleteOutletPopUpImageArgs = {
  outletId: Scalars['ID'];
};


export type MutationEmailResetPasswordUrlArgs = {
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationPushNotificationArgs = {
  input: PushNotificationInput;
};


export type MutationRemoveMixinPromoArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveRestaurantUserArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveSupplierFinancingArgs = {
  purchaseOrderId: Scalars['ID'];
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationToggleDiscountCodeArgs = {
  id: Scalars['ID'];
};


export type MutationToggleEnableMailingArgs = {
  toggle: Scalars['Boolean'];
};


export type MutationToggleFirstOrderPromoArgs = {
  toggle: Scalars['Boolean'];
};


export type MutationUpdateBookingViewingEmailsArgs = {
  emails: Array<Scalars['String']>;
};


export type MutationUpdateConsumerAnnouncementArgs = {
  announcement: Scalars['String'];
};


export type MutationUpdateDiscountCodeArgs = {
  id: Scalars['ID'];
  input: UpdateDiscountCodeInput;
};


export type MutationUpdateKitchenAppVersionArgs = {
  version: Scalars['String'];
  downloadUrl: Scalars['String'];
};


export type MutationUpdateMainPopUpImageArgs = {
  image: Scalars['File'];
};


export type MutationUpdateOperatingCountriesArgs = {
  countries: Array<Scalars['String']>;
};


export type MutationUpdateOutletArgs = {
  id: Scalars['ID'];
  input: UpdateOutletInput;
};


export type MutationUpdateOutletPopUpImageArgs = {
  outletId: Scalars['ID'];
  image: Scalars['File'];
};


export type MutationUpdateRestaurantArgs = {
  id: Scalars['ID'];
  input: UpdateRestaurantInput;
};


export type MutationUpdateRestaurantAdminArgs = {
  id: Scalars['ID'];
  input: UpdateRestaurantAdminInput;
};


export type MutationUpdateRestaurantUserArgs = {
  id: Scalars['ID'];
  input: UpdateRestaurantUserInput;
};


export type MutationUpdateStoreAutoAssignEmailsArgs = {
  emails: Array<Scalars['String']>;
};


export type MutationUpdateSupplyBnplArgs = {
  percentage: Scalars['Float'];
  rate: Scalars['String'];
  durationForRate: Scalars['Int'];
};

export enum OrderState {
  Unfulfilled = 'unfulfilled',
  Accepted = 'accepted',
  Readied = 'readied',
  Collected = 'collected',
  Finished = 'finished',
  Cancelled = 'cancelled'
}

export type Outlet = {
  __typename?: 'Outlet';
  address: Scalars['String'];
  cabinetSlots?: Maybe<Array<CabinetSlot>>;
  close: Scalars['String'];
  hide: Scalars['Boolean'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  open: Scalars['String'];
  popUpImage?: Maybe<Scalars['String']>;
  restaurants?: Maybe<Array<Restaurant>>;
  slides?: Maybe<Array<Scalars['String']>>;
  slidesUrls?: Maybe<Array<Scalars['String']>>;
};

export enum PaymentMethod {
  Fpx = 'fpx',
  Card = 'card',
  Cash = 'cash',
  Grabpay = 'grabpay',
  Qr = 'qr'
}

export type PopUpImage = {
  __typename?: 'PopUpImage';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
};

export type PushNotificationInput = {
  title: Scalars['String'];
  body: Scalars['String'];
};

/** Autogenerated return type of PushNotification. */
export type PushNotificationPayload = {
  __typename?: 'PushNotificationPayload';
  errors?: Maybe<Array<Error>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  allConsumers?: Maybe<Consumers>;
  allConsumersBasedOnLastOrder?: Maybe<Consumers>;
  analyticsConsumersUrl: Scalars['String'];
  app?: Maybe<App>;
  bnpl?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Category>>;
  consumerCsv?: Maybe<Scalars['String']>;
  consumerLastOrderCsv?: Maybe<Scalars['String']>;
  discountCode?: Maybe<DiscountCode>;
  discountCodes?: Maybe<Array<DiscountCode>>;
  kitchenSalesBreakdown?: Maybe<KitchenSalesBreakdownTotal>;
  kitchenSalesComparisonOutlet?: Maybe<KitchenSalesComparisonTotal>;
  kitchenSalesComparisonRestaurant?: Maybe<KitchenSalesComparisonTotal>;
  me?: Maybe<Admin>;
  merchantSales?: Maybe<MerchantSales>;
  merchantSalesCsv?: Maybe<Scalars['String']>;
  merchantSalesTotal?: Maybe<MerchantSalesTotal>;
  mixOrders?: Maybe<Array<MixOrder>>;
  mixinPromo?: Maybe<MixinPromo>;
  mixinPromos?: Maybe<Array<MixinPromo>>;
  outlet?: Maybe<Outlet>;
  outlets?: Maybe<Array<Outlet>>;
  popUpImage?: Maybe<PopUpImage>;
  restaurant?: Maybe<Restaurant>;
  restaurantAdmin?: Maybe<RestaurantUser>;
  restaurantAdmins?: Maybe<Array<RestaurantUser>>;
  restaurants?: Maybe<Array<Restaurant>>;
  settings?: Maybe<Settings>;
  settlements?: Maybe<Array<Settlement>>;
  settlementsDownload?: Maybe<Scalars['String']>;
  settlementsSummary?: Maybe<Array<Settlement>>;
  stores?: Maybe<Array<Store>>;
  storesMultiOutlets?: Maybe<Array<Store>>;
  supplierPurchaseOrder?: Maybe<SupplierPurchaseOrder>;
  supplierPurchaseOrders?: Maybe<Array<SupplierPurchaseOrder>>;
  suppliers?: Maybe<Array<Supplier>>;
  totalOrdersCount: Scalars['Int'];
  totalUsersCount: Scalars['Int'];
  uncollectedOrders?: Maybe<Array<MixOrder>>;
  viewingBookings?: Maybe<Array<ViewingBooking>>;
};


export type QueryAllConsumersArgs = {
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
  search?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
};


export type QueryAllConsumersBasedOnLastOrderArgs = {
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
  search?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
};


export type QueryConsumerCsvArgs = {
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
};


export type QueryConsumerLastOrderCsvArgs = {
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
};


export type QueryDiscountCodeArgs = {
  id: Scalars['ID'];
};


export type QueryDiscountCodesArgs = {
  states?: Maybe<Array<DiscountCodeState>>;
};


export type QueryKitchenSalesBreakdownArgs = {
  outletIds: Array<Scalars['ID']>;
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
};


export type QueryKitchenSalesComparisonOutletArgs = {
  outletIds: Array<Scalars['ID']>;
  integrations?: Maybe<Array<Integration>>;
  monthCount?: Maybe<Scalars['Int']>;
};


export type QueryKitchenSalesComparisonRestaurantArgs = {
  restaurantId: Scalars['ID'];
  integrations?: Maybe<Array<Integration>>;
  monthCount?: Maybe<Scalars['Int']>;
};


export type QueryMerchantSalesArgs = {
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
  integrations?: Maybe<Array<Integration>>;
  states?: Maybe<Array<OrderState>>;
  storeIds?: Maybe<Array<Scalars['ID']>>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  page: Scalars['Int'];
};


export type QueryMerchantSalesCsvArgs = {
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
  integrations?: Maybe<Array<Integration>>;
  states?: Maybe<Array<OrderState>>;
  storeIds?: Maybe<Array<Scalars['ID']>>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
};


export type QueryMerchantSalesTotalArgs = {
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
  integrations?: Maybe<Array<Integration>>;
  states?: Maybe<Array<OrderState>>;
  storeIds?: Maybe<Array<Scalars['ID']>>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
};


export type QueryMixOrdersArgs = {
  states: Array<OrderState>;
  storeIds: Array<Scalars['ID']>;
  deliveryOptions: Array<DeliveryOption>;
};


export type QueryMixinPromoArgs = {
  id: Scalars['ID'];
};


export type QueryOutletArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantAdminArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantsArgs = {
  outletIds: Array<Scalars['ID']>;
};


export type QuerySettlementsArgs = {
  restaurantIds: Array<Scalars['ID']>;
  dateRanges: Array<DateRanges>;
};


export type QuerySettlementsDownloadArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerySettlementsSummaryArgs = {
  dateRanges: Array<DateRanges>;
};


export type QueryStoresArgs = {
  restaurantIds: Array<Scalars['ID']>;
};


export type QueryStoresMultiOutletsArgs = {
  outletIds?: Maybe<Array<Scalars['ID']>>;
};


export type QuerySupplierPurchaseOrderArgs = {
  id: Scalars['ID'];
};


export type QuerySupplierPurchaseOrdersArgs = {
  states: Array<SupplierPurchaseOrderRestaurantUserState>;
};


export type QueryUncollectedOrdersArgs = {
  outletIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of RemoveFinancing. */
export type RemoveFinancingPayload = {
  __typename?: 'RemoveFinancingPayload';
  errors?: Maybe<Array<Error>>;
  purchaseOrder?: Maybe<SupplierPurchaseOrder>;
};

/** Autogenerated return type of RemoveMixinPromo. */
export type RemoveMixinPromoPayload = {
  __typename?: 'RemoveMixinPromoPayload';
  errors?: Maybe<Array<Error>>;
  mixinPromo: Array<MixinPromo>;
};

/** Autogenerated return type of RemoveRestaurantUser. */
export type RemoveRestaurantUserPayload = {
  __typename?: 'RemoveRestaurantUserPayload';
  errors?: Maybe<Array<Error>>;
  restaurantUser?: Maybe<RestaurantUser>;
};

export type ResetPasswordInput = {
  token: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};

/** Autogenerated return type of ResetPassword. */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  errors?: Maybe<Array<Error>>;
  user?: Maybe<Admin>;
};

export type Restaurant = {
  __typename?: 'Restaurant';
  cabinetSlots?: Maybe<Array<CabinetSlot>>;
  counterFeePercentage: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  outlet: Outlet;
  restaurantUser?: Maybe<RestaurantUser>;
  restaurantUsers?: Maybe<Array<RestaurantUser>>;
  revenueSharePercentage: Scalars['Float'];
  revenueSharePercentageNew?: Maybe<Scalars['Float']>;
  revenueSharePercentageNewEffectiveDate?: Maybe<Scalars['ISO8601DateTime']>;
  stores?: Maybe<Array<Store>>;
  taxPercentage: Scalars['Float'];
  techFeePercentage: Scalars['Float'];
};


export type RestaurantRestaurantUserArgs = {
  id: Scalars['ID'];
};

export type RestaurantUser = {
  __typename?: 'RestaurantUser';
  countryCode: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isBanned?: Maybe<Scalars['Boolean']>;
  isCashier: Scalars['Boolean'];
  nationalNumber: Scalars['String'];
  phoneNumber: Scalars['String'];
  role: RestaurantUserRole;
  stores?: Maybe<Array<Store>>;
};

export enum RestaurantUserRole {
  SuperAdmin = 'super_admin',
  KitchenStaff = 'kitchen_staff',
  Owner = 'owner'
}

export type Settings = {
  __typename?: 'Settings';
  bookingViewingEmails?: Maybe<Array<Scalars['String']>>;
  consumerAnnouncement?: Maybe<Scalars['String']>;
  enableMailing: Scalars['Boolean'];
  firstOrder: Scalars['Boolean'];
  kitchenAppDownloadUrl?: Maybe<Scalars['String']>;
  kitchenAppVersion?: Maybe<Scalars['String']>;
  operatingCountries?: Maybe<Array<Scalars['String']>>;
  storeAutoAssignEmails?: Maybe<Array<Scalars['String']>>;
  supplyBnpl?: Maybe<Scalars['String']>;
};

export type Settlement = {
  __typename?: 'Settlement';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  pdf?: Maybe<Scalars['String']>;
  restaurant: Restaurant;
};

export type Store = {
  __typename?: 'Store';
  address?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  restaurant: Restaurant;
  variant: StoreVariant;
};

export type StoreDiscountCodes = {
  __typename?: 'StoreDiscountCodes';
  id: Scalars['ID'];
  remains: Scalars['Int'];
  storeId: Scalars['ID'];
  storeName: Scalars['String'];
  totalUsageLimit: Scalars['Int'];
};

export type StoreDiscountCodesInput = {
  storeId: Scalars['ID'];
  totalUsageLimit: Scalars['Int'];
};

export type StoreOrder = {
  __typename?: 'StoreOrder';
  cabinetSlotNumber?: Maybe<Scalars['String']>;
  counterFee: Scalars['Float'];
  counterFeePercentage: Scalars['Float'];
  createdAt: Scalars['ISO8601DateTime'];
  discountCode?: Maybe<Scalars['String']>;
  externalFee: Scalars['Float'];
  finalPrice: Scalars['Float'];
  id: Scalars['ID'];
  integration?: Maybe<Integration>;
  isFirstMobileOrder: Scalars['Boolean'];
  merchantFundedPromo: Scalars['Float'];
  netIncome: Scalars['Float'];
  orderCode: Scalars['String'];
  orderId: Scalars['String'];
  originalPrice: Scalars['Float'];
  paymentMethod?: Maybe<PaymentMethod>;
  platformFee: Scalars['Float'];
  platformFeePercentage: Scalars['Float'];
  platformFundedPromo: Scalars['Float'];
  revenue: Scalars['Float'];
  revenueShare: Scalars['Float'];
  revenueSharePercentage: Scalars['Float'];
  state: OrderState;
  store: Store;
  storeName: Scalars['String'];
  tax: Scalars['Float'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum StoreVariant {
  Food = 'food',
  Mart = 'mart'
}

export type Supplier = {
  __typename?: 'Supplier';
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  minOrderPrice: Scalars['Int'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export enum SupplierFinancing {
  None = 'none',
  Reviewing = 'reviewing',
  Accepted = 'accepted',
  Paid = 'paid',
  Rejected = 'rejected'
}

export type SupplierItem = {
  __typename?: 'SupplierItem';
  deliveryDate?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  price: Scalars['Int'];
  quantity?: Maybe<Scalars['Int']>;
};

export type SupplierPurchaseOrder = {
  __typename?: 'SupplierPurchaseOrder';
  createdAt: Scalars['ISO8601DateTime'];
  financingState: SupplierFinancing;
  id: Scalars['ID'];
  invoice?: Maybe<Scalars['String']>;
  invoicedAt?: Maybe<Scalars['ISO8601DateTime']>;
  items: Array<SupplierItem>;
  orderCode: Scalars['String'];
  paymentProof?: Maybe<Scalars['String']>;
  paymentProvedAt?: Maybe<Scalars['ISO8601DateTime']>;
  price: Scalars['Int'];
  rejectReason?: Maybe<Scalars['String']>;
  report?: Maybe<Scalars['String']>;
  restaurantUser: RestaurantUser;
  state?: Maybe<SupplierPurchaseOrderRestaurantUserState>;
  supplier: Supplier;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum SupplierPurchaseOrderRestaurantUserState {
  Created = 'created',
  Acknowledged = 'acknowledged',
  SupplierRejected = 'supplier_rejected',
  Invoiced = 'invoiced',
  FundingReviewing = 'funding_reviewing',
  FundingRejected = 'funding_rejected',
  Paid = 'paid',
  Scheduled = 'scheduled',
  Delivering = 'delivering',
  Delivered = 'delivered',
  Cancelled = 'cancelled'
}

/** Autogenerated return type of ToggleDiscountCode. */
export type ToggleDiscountCodePayload = {
  __typename?: 'ToggleDiscountCodePayload';
  discountCode?: Maybe<DiscountCode>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated return type of ToggleEnableMailing. */
export type ToggleEnableMailingPayload = {
  __typename?: 'ToggleEnableMailingPayload';
  errors?: Maybe<Array<Error>>;
  toggle?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ToggleFirstOrderPromo. */
export type ToggleFirstOrderPromoPayload = {
  __typename?: 'ToggleFirstOrderPromoPayload';
  errors?: Maybe<Array<Error>>;
  toggle?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateBookingViewingEmails. */
export type UpdateBookingViewingEmailsPayload = {
  __typename?: 'UpdateBookingViewingEmailsPayload';
  emails?: Maybe<Array<Scalars['String']>>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated return type of UpdateConsumerAnnouncement. */
export type UpdateConsumerAnnouncementPayload = {
  __typename?: 'UpdateConsumerAnnouncementPayload';
  announcement?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

export type UpdateDiscountCodeInput = {
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  discountVariant?: Maybe<DiscountVariant>;
  deliveryOptions?: Maybe<Array<DeliveryOption>>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  amount?: Maybe<Scalars['Float']>;
  shouldAutoIncludeNewStore?: Maybe<Scalars['Boolean']>;
  maximumDiscountAmount?: Maybe<Scalars['Float']>;
  minimumAmountToApply?: Maybe<Scalars['Float']>;
  dailyLimit?: Maybe<Scalars['Int']>;
  totalUsageLimit?: Maybe<Scalars['Int']>;
  platformFundedPercentage?: Maybe<Scalars['Float']>;
  validFrom?: Maybe<Scalars['ISO8601DateTime']>;
  validUntil?: Maybe<Scalars['ISO8601DateTime']>;
  storeDiscountCodes?: Maybe<Array<StoreDiscountCodesInput>>;
};

/** Autogenerated return type of UpdateDiscountCode. */
export type UpdateDiscountCodePayload = {
  __typename?: 'UpdateDiscountCodePayload';
  discountCode?: Maybe<DiscountCode>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated return type of UpdateKitchenAppVersion. */
export type UpdateKitchenAppVersionPayload = {
  __typename?: 'UpdateKitchenAppVersionPayload';
  downloadUrl?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  version?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateMainPopUpImage. */
export type UpdateMainPopUpImagePayload = {
  __typename?: 'UpdateMainPopUpImagePayload';
  errors?: Maybe<Array<Error>>;
  popUpImage?: Maybe<PopUpImage>;
};

/** Autogenerated return type of UpdateOperatingCountries. */
export type UpdateOperatingCountriesPayload = {
  __typename?: 'UpdateOperatingCountriesPayload';
  countries?: Maybe<Array<Scalars['String']>>;
  errors?: Maybe<Array<Error>>;
};

export type UpdateOutletInput = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  open?: Maybe<Scalars['String']>;
  close?: Maybe<Scalars['String']>;
  hide?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['File']>;
  slides?: Maybe<Array<Scalars['File']>>;
  slidesUrls?: Maybe<Array<Scalars['String']>>;
  cabinetSlots?: Maybe<Array<Scalars['Int']>>;
};

/** Autogenerated return type of UpdateOutlet. */
export type UpdateOutletPayload = {
  __typename?: 'UpdateOutletPayload';
  errors?: Maybe<Array<Error>>;
  outlet?: Maybe<Outlet>;
};

/** Autogenerated return type of UpdateOutletPopUpImage. */
export type UpdateOutletPopUpImagePayload = {
  __typename?: 'UpdateOutletPopUpImagePayload';
  errors?: Maybe<Array<Error>>;
  outlet?: Maybe<Outlet>;
};

export type UpdateRestaurantAdminInput = {
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  isCashier?: Maybe<Scalars['Boolean']>;
  storeIds?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of UpdateRestaurantAdmin. */
export type UpdateRestaurantAdminPayload = {
  __typename?: 'UpdateRestaurantAdminPayload';
  errors?: Maybe<Array<Error>>;
  restaurantAdmin?: Maybe<RestaurantUser>;
};

export type UpdateRestaurantInput = {
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  outletId: Scalars['ID'];
  cabinetSlots?: Maybe<Array<Scalars['Int']>>;
  taxPercentage?: Maybe<Scalars['Float']>;
  counterFeePercentage?: Maybe<Scalars['Float']>;
  techFeePercentage?: Maybe<Scalars['Float']>;
  revenueSharePercentage?: Maybe<Scalars['Float']>;
  revenueSharePercentageNew?: Maybe<Scalars['Float']>;
  revenueSharePercentageNewEffectiveDate?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of UpdateRestaurant. */
export type UpdateRestaurantPayload = {
  __typename?: 'UpdateRestaurantPayload';
  errors?: Maybe<Array<Error>>;
  restaurant?: Maybe<Restaurant>;
};

export type UpdateRestaurantUserInput = {
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<RestaurantUserRole>;
  isCashier?: Maybe<Scalars['Boolean']>;
  storeIds?: Maybe<Array<Scalars['ID']>>;
  isBanned?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateRestaurantUser. */
export type UpdateRestaurantUserPayload = {
  __typename?: 'UpdateRestaurantUserPayload';
  errors?: Maybe<Array<Error>>;
  restaurantUser?: Maybe<RestaurantUser>;
};

/** Autogenerated return type of UpdateStoreAutoAssignEmails. */
export type UpdateStoreAutoAssignEmailsPayload = {
  __typename?: 'UpdateStoreAutoAssignEmailsPayload';
  emails?: Maybe<Array<Scalars['String']>>;
  errors?: Maybe<Array<Error>>;
};

/** Autogenerated return type of UpdateSupplyBnpl. */
export type UpdateSupplyBnplPayload = {
  __typename?: 'UpdateSupplyBnplPayload';
  errors?: Maybe<Array<Error>>;
  supplyBnpl?: Maybe<Scalars['String']>;
};

export type ViewingBooking = {
  __typename?: 'ViewingBooking';
  brand: Scalars['String'];
  date: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  location: Scalars['String'];
  phoneNumber: Scalars['String'];
  timeSlot: Scalars['String'];
};

export type ErrorFragment = (
  { __typename?: 'Error' }
  & Pick<Error, 'path' | 'detail'>
);

export type SuppliersFragment = (
  { __typename?: 'Supplier' }
  & Pick<Supplier, 'id' | 'name' | 'email' | 'phoneNumber' | 'createdAt'>
);

export type SupplierPurchaseOrdersFragment = (
  { __typename?: 'SupplierPurchaseOrder' }
  & Pick<SupplierPurchaseOrder, 'id' | 'orderCode' | 'createdAt' | 'updatedAt' | 'state' | 'price'>
  & { supplier: (
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name'>
  ), restaurantUser: (
    { __typename?: 'RestaurantUser' }
    & Pick<RestaurantUser, 'id' | 'email'>
  ) }
);

export type SupplierPurchaseOrderFragment = (
  { __typename?: 'SupplierPurchaseOrder' }
  & Pick<SupplierPurchaseOrder, 'id' | 'orderCode' | 'createdAt' | 'invoicedAt' | 'paymentProvedAt' | 'price' | 'state' | 'financingState' | 'rejectReason' | 'report' | 'invoice' | 'paymentProof'>
  & { restaurantUser: (
    { __typename?: 'RestaurantUser' }
    & Pick<RestaurantUser, 'id' | 'email' | 'phoneNumber'>
  ), supplier: (
    { __typename?: 'Supplier' }
    & Pick<Supplier, 'id' | 'name' | 'phoneNumber'>
  ) }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'LoginPayload' }
    & Pick<LoginPayload, 'jwt'>
    & { me?: Maybe<(
      { __typename?: 'Admin' }
      & Pick<Admin, 'id' | 'email' | 'role'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateCategoryMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCategory?: Maybe<(
    { __typename?: 'CreateCategoryPayload' }
    & { category?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name' | 'popular'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ArchiveDiscountCodeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveDiscountCodeMutation = (
  { __typename?: 'Mutation' }
  & { archiveDiscountCode?: Maybe<(
    { __typename?: 'ArchiveDiscountCodePayload' }
    & { discountCode?: Maybe<(
      { __typename?: 'DiscountCode' }
      & Pick<DiscountCode, 'id' | 'state'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateDiscountCodeMutationVariables = Exact<{
  name: Scalars['String'];
  code: Scalars['String'];
  discountVariant: DiscountVariant;
  deliveryOptions: Array<DeliveryOption> | DeliveryOption;
  paymentMethods: Array<PaymentMethod> | PaymentMethod;
  shouldAutoIncludeNewStore: Scalars['Boolean'];
  amount: Scalars['Float'];
  maximumDiscountAmount: Scalars['Float'];
  minimumAmountToApply: Scalars['Float'];
  platformFundedPercentage: Scalars['Float'];
  dailyLimit?: Maybe<Scalars['Int']>;
  totalUsageLimit: Scalars['Int'];
  validFrom: Scalars['ISO8601DateTime'];
  validUntil: Scalars['ISO8601DateTime'];
  storeDiscountCodes?: Maybe<Array<StoreDiscountCodesInput> | StoreDiscountCodesInput>;
}>;


export type CreateDiscountCodeMutation = (
  { __typename?: 'Mutation' }
  & { createDiscountCode?: Maybe<(
    { __typename?: 'CreateDiscountCodePayload' }
    & { discountCode?: Maybe<(
      { __typename?: 'DiscountCode' }
      & Pick<DiscountCode, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ToggleDiscountCodeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ToggleDiscountCodeMutation = (
  { __typename?: 'Mutation' }
  & { toggleDiscountCode?: Maybe<(
    { __typename?: 'ToggleDiscountCodePayload' }
    & { discountCode?: Maybe<(
      { __typename?: 'DiscountCode' }
      & Pick<DiscountCode, 'id' | 'state'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateDiscountCodeMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  discountVariant?: Maybe<DiscountVariant>;
  deliveryOptions?: Maybe<Array<DeliveryOption> | DeliveryOption>;
  paymentMethods?: Maybe<Array<PaymentMethod> | PaymentMethod>;
  shouldAutoIncludeNewStore?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  maximumDiscountAmount?: Maybe<Scalars['Float']>;
  minimumAmountToApply?: Maybe<Scalars['Float']>;
  platformFundedPercentage?: Maybe<Scalars['Float']>;
  dailyLimit?: Maybe<Scalars['Int']>;
  totalUsageLimit?: Maybe<Scalars['Int']>;
  validFrom?: Maybe<Scalars['ISO8601DateTime']>;
  validUntil?: Maybe<Scalars['ISO8601DateTime']>;
  storeDiscountCodes?: Maybe<Array<StoreDiscountCodesInput> | StoreDiscountCodesInput>;
}>;


export type UpdateDiscountCodeMutation = (
  { __typename?: 'Mutation' }
  & { updateDiscountCode?: Maybe<(
    { __typename?: 'UpdateDiscountCodePayload' }
    & { discountCode?: Maybe<(
      { __typename?: 'DiscountCode' }
      & Pick<DiscountCode, 'id' | 'name'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateMixinPromoMutationVariables = Exact<{
  outletIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  storesCount: Scalars['Int'];
  amount: Scalars['Int'];
  category: MixinPromoCategory;
  minSpend: Scalars['Int'];
  capped: Scalars['Int'];
}>;


export type CreateMixinPromoMutation = (
  { __typename?: 'Mutation' }
  & { createMixinPromo?: Maybe<(
    { __typename?: 'CreateMixinPromoPayload' }
    & { mixinPromo?: Maybe<(
      { __typename?: 'MixinPromo' }
      & Pick<MixinPromo, 'outletIds' | 'storesCount' | 'amount' | 'category' | 'minSpend' | 'capped'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveMixinPromoMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveMixinPromoMutation = (
  { __typename?: 'Mutation' }
  & { removeMixinPromo?: Maybe<(
    { __typename?: 'RemoveMixinPromoPayload' }
    & { mixinPromo: Array<(
      { __typename?: 'MixinPromo' }
      & Pick<MixinPromo, 'id' | 'storesCount' | 'amount' | 'category' | 'minSpend' | 'capped'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type PushNotificationMutationVariables = Exact<{
  title: Scalars['String'];
  body: Scalars['String'];
}>;


export type PushNotificationMutation = (
  { __typename?: 'Mutation' }
  & { pushNotification?: Maybe<(
    { __typename?: 'PushNotificationPayload' }
    & Pick<PushNotificationPayload, 'success'>
  )> }
);

export type CompleteOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompleteOrderMutation = (
  { __typename?: 'Mutation' }
  & { completeOrder?: Maybe<(
    { __typename?: 'CompleteOrderPayload' }
    & Pick<CompleteOrderPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateOutletMutationVariables = Exact<{
  name: Scalars['String'];
  address: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  open: Scalars['String'];
  close: Scalars['String'];
  image?: Maybe<Scalars['File']>;
  slides?: Maybe<Array<Scalars['File']> | Scalars['File']>;
  slidesUrls?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  hide?: Maybe<Scalars['Boolean']>;
}>;


export type CreateOutletMutation = (
  { __typename?: 'Mutation' }
  & { createOutlet?: Maybe<(
    { __typename?: 'CreateOutletPayload' }
    & { outlet?: Maybe<(
      { __typename?: 'Outlet' }
      & Pick<Outlet, 'id' | 'name' | 'address' | 'open' | 'close' | 'latitude' | 'longitude'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateOutletMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  open?: Maybe<Scalars['String']>;
  close?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['File']>;
  slides?: Maybe<Array<Scalars['File']> | Scalars['File']>;
  slidesUrls?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  hide?: Maybe<Scalars['Boolean']>;
  cabinetSlots?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type UpdateOutletMutation = (
  { __typename?: 'Mutation' }
  & { updateOutlet?: Maybe<(
    { __typename?: 'UpdateOutletPayload' }
    & { outlet?: Maybe<(
      { __typename?: 'Outlet' }
      & Pick<Outlet, 'id' | 'name' | 'address' | 'open' | 'close' | 'latitude' | 'longitude'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type DeleteMainPopUpImageMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteMainPopUpImageMutation = (
  { __typename?: 'Mutation' }
  & { deleteMainPopUpImage?: Maybe<(
    { __typename?: 'DeleteMainPopUpImagePayload' }
    & Pick<DeleteMainPopUpImagePayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type DeleteOutletPopUpImageMutationVariables = Exact<{
  outletId: Scalars['ID'];
}>;


export type DeleteOutletPopUpImageMutation = (
  { __typename?: 'Mutation' }
  & { deleteOutletPopUpImage?: Maybe<(
    { __typename?: 'DeleteOutletPopUpImagePayload' }
    & Pick<DeleteOutletPopUpImagePayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateOutletPopUpImageMutationVariables = Exact<{
  outletId: Scalars['ID'];
  image: Scalars['File'];
}>;


export type UpdateOutletPopUpImageMutation = (
  { __typename?: 'Mutation' }
  & { updateOutletPopUpImage?: Maybe<(
    { __typename?: 'UpdateOutletPopUpImagePayload' }
    & { outlet?: Maybe<(
      { __typename?: 'Outlet' }
      & Pick<Outlet, 'id' | 'popUpImage'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateMainPopUpImageMutationVariables = Exact<{
  image: Scalars['File'];
}>;


export type UpdateMainPopUpImageMutation = (
  { __typename?: 'Mutation' }
  & { updateMainPopUpImage?: Maybe<(
    { __typename?: 'UpdateMainPopUpImagePayload' }
    & { popUpImage?: Maybe<(
      { __typename?: 'PopUpImage' }
      & Pick<PopUpImage, 'id' | 'image'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type EmailResetPasswordUrlMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type EmailResetPasswordUrlMutation = (
  { __typename?: 'Mutation' }
  & { emailResetPasswordUrl?: Maybe<(
    { __typename?: 'EmailResetPasswordUrlPayload' }
    & Pick<EmailResetPasswordUrlPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'ResetPasswordPayload' }
    & { user?: Maybe<(
      { __typename?: 'Admin' }
      & Pick<Admin, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateRestaurantMutationVariables = Exact<{
  name: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  outletId: Scalars['ID'];
  taxPercentage: Scalars['Float'];
  counterFeePercentage: Scalars['Float'];
  techFeePercentage: Scalars['Float'];
  revenueSharePercentage: Scalars['Float'];
}>;


export type CreateRestaurantMutation = (
  { __typename?: 'Mutation' }
  & { createRestaurant?: Maybe<(
    { __typename?: 'CreateRestaurantPayload' }
    & { restaurant?: Maybe<(
      { __typename?: 'Restaurant' }
      & Pick<Restaurant, 'id' | 'name' | 'number' | 'taxPercentage' | 'counterFeePercentage' | 'techFeePercentage' | 'revenueSharePercentage'>
      & { outlet: (
        { __typename?: 'Outlet' }
        & Pick<Outlet, 'id' | 'name'>
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateRestaurantMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  cabinetSlots?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  outletId: Scalars['ID'];
  taxPercentage?: Maybe<Scalars['Float']>;
  counterFeePercentage?: Maybe<Scalars['Float']>;
  techFeePercentage?: Maybe<Scalars['Float']>;
  revenueSharePercentage?: Maybe<Scalars['Float']>;
  revenueSharePercentageNew?: Maybe<Scalars['Float']>;
  revenueSharePercentageNewEffectiveDate?: Maybe<Scalars['ISO8601DateTime']>;
}>;


export type UpdateRestaurantMutation = (
  { __typename?: 'Mutation' }
  & { updateRestaurant?: Maybe<(
    { __typename?: 'UpdateRestaurantPayload' }
    & { restaurant?: Maybe<(
      { __typename?: 'Restaurant' }
      & Pick<Restaurant, 'id' | 'name' | 'number' | 'taxPercentage' | 'counterFeePercentage' | 'techFeePercentage' | 'revenueSharePercentage' | 'revenueSharePercentageNew' | 'revenueSharePercentageNewEffectiveDate'>
      & { outlet: (
        { __typename?: 'Outlet' }
        & Pick<Outlet, 'id' | 'name'>
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateRestaurantAdminMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  isCashier: Scalars['Boolean'];
  storeIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CreateRestaurantAdminMutation = (
  { __typename?: 'Mutation' }
  & { createRestaurantAdmin?: Maybe<(
    { __typename?: 'CreateRestaurantAdminPayload' }
    & { restaurantAdmin?: Maybe<(
      { __typename?: 'RestaurantUser' }
      & Pick<RestaurantUser, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateRestaurantAdminMutationVariables = Exact<{
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  isCashier: Scalars['Boolean'];
  storeIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type UpdateRestaurantAdminMutation = (
  { __typename?: 'Mutation' }
  & { updateRestaurantAdmin?: Maybe<(
    { __typename?: 'UpdateRestaurantAdminPayload' }
    & { restaurantAdmin?: Maybe<(
      { __typename?: 'RestaurantUser' }
      & Pick<RestaurantUser, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateRestaurantUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  isCashier: Scalars['Boolean'];
  role: RestaurantUserRole;
  restaurantId: Scalars['ID'];
  storeIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  isBanned: Scalars['Boolean'];
}>;


export type CreateRestaurantUserMutation = (
  { __typename?: 'Mutation' }
  & { createRestaurantUser?: Maybe<(
    { __typename?: 'CreateRestaurantUserPayload' }
    & { restaurantUser?: Maybe<(
      { __typename?: 'RestaurantUser' }
      & Pick<RestaurantUser, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveRestaurantUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveRestaurantUserMutation = (
  { __typename?: 'Mutation' }
  & { removeRestaurantUser?: Maybe<(
    { __typename?: 'RemoveRestaurantUserPayload' }
    & { restaurantUser?: Maybe<(
      { __typename?: 'RestaurantUser' }
      & Pick<RestaurantUser, 'id' | 'email'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateRestaurantUserMutationVariables = Exact<{
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  isCashier?: Maybe<Scalars['Boolean']>;
  role?: Maybe<RestaurantUserRole>;
  storeIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  isBanned: Scalars['Boolean'];
}>;


export type UpdateRestaurantUserMutation = (
  { __typename?: 'Mutation' }
  & { updateRestaurantUser?: Maybe<(
    { __typename?: 'UpdateRestaurantUserPayload' }
    & { restaurantUser?: Maybe<(
      { __typename?: 'RestaurantUser' }
      & Pick<RestaurantUser, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ToggleEnableMailingMutationVariables = Exact<{
  toggle: Scalars['Boolean'];
}>;


export type ToggleEnableMailingMutation = (
  { __typename?: 'Mutation' }
  & { toggleEnableMailing?: Maybe<(
    { __typename?: 'ToggleEnableMailingPayload' }
    & Pick<ToggleEnableMailingPayload, 'toggle'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type ToggleFirstOrderPromoMutationVariables = Exact<{
  toggle: Scalars['Boolean'];
}>;


export type ToggleFirstOrderPromoMutation = (
  { __typename?: 'Mutation' }
  & { toggleFirstOrderPromo?: Maybe<(
    { __typename?: 'ToggleFirstOrderPromoPayload' }
    & Pick<ToggleFirstOrderPromoPayload, 'toggle'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateBookingViewingEmailsMutationVariables = Exact<{
  emails: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateBookingViewingEmailsMutation = (
  { __typename?: 'Mutation' }
  & { updateBookingViewingEmails?: Maybe<(
    { __typename?: 'UpdateBookingViewingEmailsPayload' }
    & Pick<UpdateBookingViewingEmailsPayload, 'emails'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateConsumerAnnouncementMutationVariables = Exact<{
  announcement: Scalars['String'];
}>;


export type UpdateConsumerAnnouncementMutation = (
  { __typename?: 'Mutation' }
  & { updateConsumerAnnouncement?: Maybe<(
    { __typename?: 'UpdateConsumerAnnouncementPayload' }
    & Pick<UpdateConsumerAnnouncementPayload, 'announcement'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateKitchenAppVersionMutationVariables = Exact<{
  version: Scalars['String'];
  downloadUrl: Scalars['String'];
}>;


export type UpdateKitchenAppVersionMutation = (
  { __typename?: 'Mutation' }
  & { updateKitchenAppVersion?: Maybe<(
    { __typename?: 'UpdateKitchenAppVersionPayload' }
    & Pick<UpdateKitchenAppVersionPayload, 'version' | 'downloadUrl'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateOperatingCountriesMutationVariables = Exact<{
  countries: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateOperatingCountriesMutation = (
  { __typename?: 'Mutation' }
  & { updateOperatingCountries?: Maybe<(
    { __typename?: 'UpdateOperatingCountriesPayload' }
    & Pick<UpdateOperatingCountriesPayload, 'countries'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateStoreAutoAssignEmailsMutationVariables = Exact<{
  emails: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateStoreAutoAssignEmailsMutation = (
  { __typename?: 'Mutation' }
  & { updateStoreAutoAssignEmails?: Maybe<(
    { __typename?: 'UpdateStoreAutoAssignEmailsPayload' }
    & Pick<UpdateStoreAutoAssignEmailsPayload, 'emails'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateSupplyBnplMutationVariables = Exact<{
  percentage: Scalars['Float'];
  rate: Scalars['String'];
  durationForRate: Scalars['Int'];
}>;


export type UpdateSupplyBnplMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplyBnpl?: Maybe<(
    { __typename?: 'UpdateSupplyBnplPayload' }
    & Pick<UpdateSupplyBnplPayload, 'supplyBnpl'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreatePaymentProofMutationVariables = Exact<{
  file: Scalars['File'];
  purchaseOrderId: Scalars['ID'];
}>;


export type CreatePaymentProofMutation = (
  { __typename?: 'Mutation' }
  & { createSupplierPaymentProof?: Maybe<(
    { __typename?: 'CreatePaymentProofPayload' }
    & { purchaseOrder?: Maybe<(
      { __typename?: 'SupplierPurchaseOrder' }
      & SupplierPurchaseOrderFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type CreateSupplierMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
}>;


export type CreateSupplierMutation = (
  { __typename?: 'Mutation' }
  & { createSupplier?: Maybe<(
    { __typename?: 'CreateSupplierPayload' }
    & { supplier?: Maybe<(
      { __typename?: 'Supplier' }
      & SuppliersFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RemoveFinancingMutationVariables = Exact<{
  purchaseOrderId: Scalars['ID'];
}>;


export type RemoveFinancingMutation = (
  { __typename?: 'Mutation' }
  & { removeSupplierFinancing?: Maybe<(
    { __typename?: 'RemoveFinancingPayload' }
    & { purchaseOrder?: Maybe<(
      { __typename?: 'SupplierPurchaseOrder' }
      & SupplierPurchaseOrderFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type AnalyticsConsumersPageQueryVariables = Exact<{ [key: string]: never; }>;


export type AnalyticsConsumersPageQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'analyticsConsumersUrl'>
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )> }
);

export type CategoriesPageQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )>, categories?: Maybe<Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'popular'>
  )>> }
);

export type UpdateDiscountCodePageQueryVariables = Exact<{ [key: string]: never; }>;


export type UpdateDiscountCodePageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )>, outlets?: Maybe<Array<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name'>
  )>> }
);

export type CreateDiscountCodePageQueryVariables = Exact<{ [key: string]: never; }>;


export type CreateDiscountCodePageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )>, outlets?: Maybe<Array<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name'>
  )>> }
);

export type DiscountCodesPageQueryVariables = Exact<{
  states?: Maybe<Array<DiscountCodeState> | DiscountCodeState>;
}>;


export type DiscountCodesPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )>, discountCodes?: Maybe<Array<(
    { __typename?: 'DiscountCode' }
    & Pick<DiscountCode, 'id' | 'name' | 'code' | 'discountVariant' | 'deliveryOptions' | 'amount' | 'state' | 'maximumDiscountAmount' | 'minimumAmountToApply' | 'validFrom' | 'validUntil' | 'platformFundedPercentage' | 'totalUsageLimit' | 'remains'>
  )>> }
);

export type MixinPromoUpdatePageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MixinPromoUpdatePageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )>, mixinPromo?: Maybe<(
    { __typename?: 'MixinPromo' }
    & Pick<MixinPromo, 'id' | 'outletIds' | 'storesCount' | 'amount' | 'category' | 'minSpend' | 'capped'>
  )> }
);

export type MixinPromoPageQueryVariables = Exact<{ [key: string]: never; }>;


export type MixinPromoPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )>, mixinPromos?: Maybe<Array<(
    { __typename?: 'MixinPromo' }
    & Pick<MixinPromo, 'id' | 'outletNames' | 'storesCount' | 'amount' | 'category' | 'minSpend' | 'capped'>
  )>> }
);

export type OutletPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OutletPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )>, outlet?: Maybe<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name'>
    & { restaurants?: Maybe<Array<(
      { __typename?: 'Restaurant' }
      & Pick<Restaurant, 'id' | 'name' | 'number'>
      & { restaurantUsers?: Maybe<Array<(
        { __typename?: 'RestaurantUser' }
        & Pick<RestaurantUser, 'id' | 'email' | 'role' | 'phoneNumber' | 'createdAt'>
      )>> }
    )>> }
  )> }
);

export type UpdateRestaurantPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UpdateRestaurantPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )>, outlets?: Maybe<Array<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name'>
  )>>, restaurant?: Maybe<(
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'id' | 'name' | 'number' | 'taxPercentage' | 'counterFeePercentage' | 'techFeePercentage' | 'revenueSharePercentage' | 'revenueSharePercentageNew' | 'revenueSharePercentageNewEffectiveDate'>
    & { cabinetSlots?: Maybe<Array<(
      { __typename?: 'CabinetSlot' }
      & Pick<CabinetSlot, 'id' | 'number'>
    )>> }
  )> }
);

export type UpdateRestaurantUserPageQueryVariables = Exact<{
  id: Scalars['ID'];
  restaurantUserId: Scalars['ID'];
}>;


export type UpdateRestaurantUserPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )>, restaurant?: Maybe<(
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'id' | 'name'>
    & { stores?: Maybe<Array<(
      { __typename?: 'Store' }
      & Pick<Store, 'id' | 'name'>
    )>>, restaurantUser?: Maybe<(
      { __typename?: 'RestaurantUser' }
      & Pick<RestaurantUser, 'id' | 'email' | 'countryCode' | 'nationalNumber' | 'isCashier' | 'isBanned' | 'role'>
      & { stores?: Maybe<Array<(
        { __typename?: 'Store' }
        & Pick<Store, 'id'>
      )>> }
    )> }
  )> }
);

export type CreateRestaurantUserPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CreateRestaurantUserPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )>, restaurant?: Maybe<(
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'id' | 'name'>
    & { stores?: Maybe<Array<(
      { __typename?: 'Store' }
      & Pick<Store, 'id' | 'name'>
    )>> }
  )> }
);

export type OutletUpdatePageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OutletUpdatePageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )>, outlet?: Maybe<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name' | 'address' | 'open' | 'close' | 'image' | 'slides' | 'slidesUrls' | 'hide'>
    & { cabinetSlots?: Maybe<Array<(
      { __typename?: 'CabinetSlot' }
      & Pick<CabinetSlot, 'id' | 'number'>
    )>> }
  )> }
);

export type OutletsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type OutletsPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )>, outlets?: Maybe<Array<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name' | 'image'>
  )>> }
);

export type UpdateRestaurantAdminPageQueryVariables = Exact<{
  restaurantAdminId: Scalars['ID'];
}>;


export type UpdateRestaurantAdminPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )>, outlets?: Maybe<Array<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name'>
    & { restaurants?: Maybe<Array<(
      { __typename?: 'Restaurant' }
      & Pick<Restaurant, 'id'>
      & { stores?: Maybe<Array<(
        { __typename?: 'Store' }
        & Pick<Store, 'id' | 'name'>
      )>> }
    )>> }
  )>>, restaurantAdmin?: Maybe<(
    { __typename?: 'RestaurantUser' }
    & Pick<RestaurantUser, 'id' | 'email' | 'countryCode' | 'nationalNumber' | 'isCashier'>
    & { stores?: Maybe<Array<(
      { __typename?: 'Store' }
      & Pick<Store, 'id'>
    )>> }
  )> }
);

export type CreateRestaurantAdminPageQueryVariables = Exact<{ [key: string]: never; }>;


export type CreateRestaurantAdminPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )>, outlets?: Maybe<Array<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name'>
    & { restaurants?: Maybe<Array<(
      { __typename?: 'Restaurant' }
      & Pick<Restaurant, 'id'>
      & { stores?: Maybe<Array<(
        { __typename?: 'Store' }
        & Pick<Store, 'id' | 'name'>
      )>> }
    )>> }
  )>> }
);

export type RestaurantAdminsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type RestaurantAdminsPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )>, restaurantAdmins?: Maybe<Array<(
    { __typename?: 'RestaurantUser' }
    & Pick<RestaurantUser, 'id' | 'email' | 'role' | 'phoneNumber' | 'createdAt'>
  )>> }
);

export type StoreListPageQueryVariables = Exact<{ [key: string]: never; }>;


export type StoreListPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )> }
);

export type UploadImagePageQueryVariables = Exact<{ [key: string]: never; }>;


export type UploadImagePageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'role' | 'email'>
  )>, popUpImage?: Maybe<(
    { __typename?: 'PopUpImage' }
    & Pick<PopUpImage, 'id' | 'image'>
  )>, outlets?: Maybe<Array<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name' | 'popUpImage'>
  )>> }
);

export type ViewingBookingsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewingBookingsPageQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'email' | 'role'>
  )>, viewingBookings?: Maybe<Array<(
    { __typename?: 'ViewingBooking' }
    & Pick<ViewingBooking, 'id' | 'firstName' | 'lastName' | 'phoneNumber' | 'email' | 'brand' | 'location' | 'date' | 'timeSlot'>
  )>> }
);

export type AppMetadataQueryVariables = Exact<{ [key: string]: never; }>;


export type AppMetadataQuery = (
  { __typename?: 'Query' }
  & { app?: Maybe<(
    { __typename?: 'App' }
    & Pick<App, 'id'>
    & { operatingCountries: Array<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'country' | 'code'>
      & { currency: (
        { __typename?: 'Currency' }
        & Pick<Currency, 'id' | 'symbol' | 'code'>
      ) }
    )> }
  )> }
);

export type ConsumersQueryVariables = Exact<{
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
  search?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
}>;


export type ConsumersQuery = (
  { __typename?: 'Query' }
  & { allConsumers?: Maybe<(
    { __typename?: 'Consumers' }
    & Pick<Consumers, 'id' | 'total' | 'pageLimit'>
    & { consumers?: Maybe<Array<(
      { __typename?: 'Consumer' }
      & Pick<Consumer, 'id' | 'name' | 'email' | 'phoneNumber' | 'newsletterSubscribed' | 'ordersCount' | 'createdAt'>
    )>> }
  )> }
);

export type ConsumersLastOrderQueryVariables = Exact<{
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
  search?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
}>;


export type ConsumersLastOrderQuery = (
  { __typename?: 'Query' }
  & { allConsumersBasedOnLastOrder?: Maybe<(
    { __typename?: 'Consumers' }
    & Pick<Consumers, 'id' | 'total' | 'pageLimit'>
    & { consumers?: Maybe<Array<(
      { __typename?: 'Consumer' }
      & Pick<Consumer, 'id' | 'name' | 'email' | 'phoneNumber' | 'ordersCountLastOrder' | 'lastOrderAt'>
    )>> }
  )> }
);

export type DiscountCodeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DiscountCodeQuery = (
  { __typename?: 'Query' }
  & { discountCode?: Maybe<(
    { __typename?: 'DiscountCode' }
    & Pick<DiscountCode, 'id' | 'name' | 'code' | 'discountVariant' | 'deliveryOptions' | 'paymentMethods' | 'shouldAutoIncludeNewStore' | 'amount' | 'maximumDiscountAmount' | 'minimumAmountToApply' | 'dailyLimit' | 'totalUsageLimit' | 'validFrom' | 'validUntil' | 'platformFundedPercentage'>
    & { storeDiscountCodes?: Maybe<Array<(
      { __typename?: 'StoreDiscountCodes' }
      & Pick<StoreDiscountCodes, 'id' | 'storeId' | 'storeName' | 'totalUsageLimit'>
    )>> }
  )> }
);

export type ExportMerchantSalesCsvQueryVariables = Exact<{
  storeIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  paymentMethods?: Maybe<Array<PaymentMethod> | PaymentMethod>;
  integrations?: Maybe<Array<Integration> | Integration>;
  states?: Maybe<Array<OrderState> | OrderState>;
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
}>;


export type ExportMerchantSalesCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'merchantSalesCsv'>
);

export type ExportConsumerCsvQueryVariables = Exact<{
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
}>;


export type ExportConsumerCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'consumerCsv'>
);

export type ExportConsumerLastOrderCsvQueryVariables = Exact<{
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
}>;


export type ExportConsumerLastOrderCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'consumerLastOrderCsv'>
);

export type GrowthQueryVariables = Exact<{ [key: string]: never; }>;


export type GrowthQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalUsersCount' | 'totalOrdersCount'>
);

export type KitchensQueryVariables = Exact<{
  outletIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type KitchensQuery = (
  { __typename?: 'Query' }
  & { restaurants?: Maybe<Array<(
    { __typename?: 'Restaurant' }
    & Pick<Restaurant, 'id' | 'name' | 'number'>
  )>> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'email' | 'role'>
  )> }
);

export type MixOrdersQueryVariables = Exact<{
  states: Array<OrderState> | OrderState;
  storeIds: Array<Scalars['ID']> | Scalars['ID'];
  deliveryOptions: Array<DeliveryOption> | DeliveryOption;
}>;


export type MixOrdersQuery = (
  { __typename?: 'Query' }
  & { mixOrders?: Maybe<Array<(
    { __typename?: 'MixOrder' }
    & Pick<MixOrder, 'id'>
    & { storeOrders?: Maybe<Array<(
      { __typename?: 'StoreOrder' }
      & Pick<StoreOrder, 'id' | 'state' | 'orderCode' | 'integration' | 'cabinetSlotNumber' | 'storeName' | 'updatedAt'>
    )>> }
  )>> }
);

export type UncollectedOrdersQueryVariables = Exact<{
  outletIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type UncollectedOrdersQuery = (
  { __typename?: 'Query' }
  & { uncollectedOrders?: Maybe<Array<(
    { __typename?: 'MixOrder' }
    & Pick<MixOrder, 'id' | 'orderCode' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type OutletsForDropdownQueryVariables = Exact<{ [key: string]: never; }>;


export type OutletsForDropdownQuery = (
  { __typename?: 'Query' }
  & { outlets?: Maybe<Array<(
    { __typename?: 'Outlet' }
    & Pick<Outlet, 'id' | 'name'>
  )>> }
);

export type MerchantSalesQueryVariables = Exact<{
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
  integrations?: Maybe<Array<Integration> | Integration>;
  states?: Maybe<Array<OrderState> | OrderState>;
  storeIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  paymentMethods?: Maybe<Array<PaymentMethod> | PaymentMethod>;
  page: Scalars['Int'];
}>;


export type MerchantSalesQuery = (
  { __typename?: 'Query' }
  & { merchantSales?: Maybe<(
    { __typename?: 'MerchantSales' }
    & Pick<MerchantSales, 'id' | 'pageLimit'>
    & { sales: Array<(
      { __typename?: 'StoreOrder' }
      & Pick<StoreOrder, 'id' | 'state' | 'orderCode' | 'orderId' | 'integration' | 'paymentMethod' | 'createdAt' | 'discountCode' | 'originalPrice' | 'isFirstMobileOrder' | 'platformFundedPromo' | 'merchantFundedPromo' | 'counterFeePercentage' | 'platformFeePercentage' | 'revenueSharePercentage' | 'revenue' | 'tax' | 'externalFee' | 'counterFee' | 'platformFee' | 'revenueShare' | 'netIncome'>
      & { store: (
        { __typename?: 'Store' }
        & Pick<Store, 'id' | 'name' | 'country' | 'variant'>
        & { restaurant: (
          { __typename?: 'Restaurant' }
          & Pick<Restaurant, 'id' | 'name' | 'number'>
        ) }
      ) }
    )> }
  )> }
);

export type MerchantSalesTotalQueryVariables = Exact<{
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
  integrations?: Maybe<Array<Integration> | Integration>;
  states?: Maybe<Array<OrderState> | OrderState>;
  storeIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  paymentMethods?: Maybe<Array<PaymentMethod> | PaymentMethod>;
}>;


export type MerchantSalesTotalQuery = (
  { __typename?: 'Query' }
  & { merchantSalesTotal?: Maybe<(
    { __typename?: 'MerchantSalesTotal' }
    & Pick<MerchantSalesTotal, 'id' | 'totalCount' | 'totalOriginalPrice' | 'totalPlatformFundedPromo' | 'totalMerchantFundedPromo' | 'totalRevenue' | 'totalTax' | 'totalExternalFee' | 'totalCounterFee' | 'totalPlatformFee' | 'totalRevenueShare' | 'totalNetIncome'>
  )> }
);

export type KitchenSalesBreakdownQueryVariables = Exact<{
  outletIds: Array<Scalars['ID']> | Scalars['ID'];
  startDate: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601DateTime'];
}>;


export type KitchenSalesBreakdownQuery = (
  { __typename?: 'Query' }
  & { kitchenSalesBreakdown?: Maybe<(
    { __typename?: 'KitchenSalesBreakdownTotal' }
    & Pick<KitchenSalesBreakdownTotal, 'id' | 'totalRevenue' | 'totalCounterFee' | 'totalPlatformFee' | 'totalRevenueShare'>
    & { kitchenSales?: Maybe<Array<(
      { __typename?: 'KitchenSalesBreakdown' }
      & Pick<KitchenSalesBreakdown, 'id' | 'kitchenName' | 'kitchenNumber' | 'totalRevenue' | 'totalCounterFee' | 'totalPlatformFee' | 'totalRevenueShare'>
      & { sales?: Maybe<Array<(
        { __typename?: 'KitchenSalesBreakdownItem' }
        & Pick<KitchenSalesBreakdownItem, 'id' | 'brand' | 'brandName' | 'revenue' | 'counterFee' | 'platformFee' | 'revenueShare'>
      )>> }
    )>> }
  )> }
);

export type KitchenSalesComparisonOutletQueryVariables = Exact<{
  outletIds: Array<Scalars['ID']> | Scalars['ID'];
  integrations?: Maybe<Array<Integration> | Integration>;
  monthCount?: Maybe<Scalars['Int']>;
}>;


export type KitchenSalesComparisonOutletQuery = (
  { __typename?: 'Query' }
  & { kitchenSalesComparisonOutlet?: Maybe<(
    { __typename?: 'KitchenSalesComparisonTotal' }
    & Pick<KitchenSalesComparisonTotal, 'id'>
    & { salesComparisonData?: Maybe<Array<(
      { __typename?: 'KitchenSalesComparison' }
      & Pick<KitchenSalesComparison, 'id' | 'restaurantId' | 'categoryName' | 'beforeName' | 'afterName' | 'beforeValue' | 'afterValue'>
    )>> }
  )> }
);

export type KitchenSalesComparisonRestaurantQueryVariables = Exact<{
  restaurantId: Scalars['ID'];
  integrations?: Maybe<Array<Integration> | Integration>;
  monthCount?: Maybe<Scalars['Int']>;
}>;


export type KitchenSalesComparisonRestaurantQuery = (
  { __typename?: 'Query' }
  & { kitchenSalesComparisonRestaurant?: Maybe<(
    { __typename?: 'KitchenSalesComparisonTotal' }
    & Pick<KitchenSalesComparisonTotal, 'id'>
    & { salesComparisonData?: Maybe<Array<(
      { __typename?: 'KitchenSalesComparison' }
      & Pick<KitchenSalesComparison, 'id' | 'restaurantId' | 'categoryName' | 'beforeName' | 'afterName' | 'beforeValue' | 'afterValue'>
    )>> }
  )> }
);

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = (
  { __typename?: 'Query' }
  & { settings?: Maybe<(
    { __typename?: 'Settings' }
    & Pick<Settings, 'firstOrder' | 'storeAutoAssignEmails' | 'bookingViewingEmails' | 'enableMailing' | 'operatingCountries' | 'consumerAnnouncement' | 'kitchenAppVersion' | 'kitchenAppDownloadUrl' | 'supplyBnpl'>
  )> }
);

export type SettlementsQueryVariables = Exact<{
  restaurantIds: Array<Scalars['ID']> | Scalars['ID'];
  dateRanges: Array<DateRanges> | DateRanges;
}>;


export type SettlementsQuery = (
  { __typename?: 'Query' }
  & { settlements?: Maybe<Array<(
    { __typename?: 'Settlement' }
    & Pick<Settlement, 'id' | 'pdf' | 'createdAt'>
    & { restaurant: (
      { __typename?: 'Restaurant' }
      & Pick<Restaurant, 'id' | 'name' | 'number'>
      & { outlet: (
        { __typename?: 'Outlet' }
        & Pick<Outlet, 'id' | 'name'>
      ) }
    ) }
  )>> }
);

export type SettlementsSummaryQueryVariables = Exact<{
  dateRanges: Array<DateRanges> | DateRanges;
}>;


export type SettlementsSummaryQuery = (
  { __typename?: 'Query' }
  & { settlementsSummary?: Maybe<Array<(
    { __typename?: 'Settlement' }
    & Pick<Settlement, 'id' | 'pdf' | 'createdAt'>
  )>> }
);

export type SettlementsDownloadQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type SettlementsDownloadQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'settlementsDownload'>
);

export type AllStoreQueryVariables = Exact<{
  kitchenIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type AllStoreQuery = (
  { __typename?: 'Query' }
  & { stores?: Maybe<Array<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name'>
  )>> }
);

export type StoresMultiOutletsQueryVariables = Exact<{
  outletIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type StoresMultiOutletsQuery = (
  { __typename?: 'Query' }
  & { storesMultiOutlets?: Maybe<Array<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name'>
    & { restaurant: (
      { __typename?: 'Restaurant' }
      & { outlet: (
        { __typename?: 'Outlet' }
        & Pick<Outlet, 'id'>
      ) }
    ) }
  )>> }
);

export type SuppliersQueryVariables = Exact<{ [key: string]: never; }>;


export type SuppliersQuery = (
  { __typename?: 'Query' }
  & { suppliers?: Maybe<Array<(
    { __typename?: 'Supplier' }
    & SuppliersFragment
  )>> }
);

export type SupplierPurchaseOrdersQueryVariables = Exact<{
  states: Array<SupplierPurchaseOrderRestaurantUserState> | SupplierPurchaseOrderRestaurantUserState;
}>;


export type SupplierPurchaseOrdersQuery = (
  { __typename?: 'Query' }
  & { supplierPurchaseOrders?: Maybe<Array<(
    { __typename?: 'SupplierPurchaseOrder' }
    & SupplierPurchaseOrdersFragment
  )>> }
);

export type SupplierPurchaseOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SupplierPurchaseOrderQuery = (
  { __typename?: 'Query' }
  & { supplierPurchaseOrder?: Maybe<(
    { __typename?: 'SupplierPurchaseOrder' }
    & SupplierPurchaseOrderFragment
  )> }
);

export type SupplyBnplQueryVariables = Exact<{ [key: string]: never; }>;


export type SupplyBnplQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'bnpl'>
);

export const ErrorFragmentDoc = gql`
    fragment Error on Error {
  path
  detail
}
    `;
export const SuppliersFragmentDoc = gql`
    fragment Suppliers on Supplier {
  id
  name
  email
  phoneNumber
  createdAt
}
    `;
export const SupplierPurchaseOrdersFragmentDoc = gql`
    fragment SupplierPurchaseOrders on SupplierPurchaseOrder {
  id
  orderCode
  createdAt
  updatedAt
  state
  price
  supplier {
    id
    name
  }
  restaurantUser {
    id
    email
  }
}
    `;
export const SupplierPurchaseOrderFragmentDoc = gql`
    fragment SupplierPurchaseOrder on SupplierPurchaseOrder {
  id
  orderCode
  createdAt
  invoicedAt
  paymentProvedAt
  price
  state
  financingState
  rejectReason
  restaurantUser {
    id
    email
    phoneNumber
  }
  supplier {
    id
    name
    phoneNumber
  }
  report
  invoice
  paymentProof
}
    `;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(input: {email: $email, password: $password}) {
    jwt
    me {
      id
      email
      role
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($name: String!) {
  createCategory(name: $name) {
    category {
      id
      name
      popular
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, baseOptions);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const ArchiveDiscountCodeDocument = gql`
    mutation ArchiveDiscountCode($id: ID!) {
  archiveDiscountCode(id: $id) {
    discountCode {
      id
      state
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type ArchiveDiscountCodeMutationFn = Apollo.MutationFunction<ArchiveDiscountCodeMutation, ArchiveDiscountCodeMutationVariables>;

/**
 * __useArchiveDiscountCodeMutation__
 *
 * To run a mutation, you first call `useArchiveDiscountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveDiscountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveDiscountCodeMutation, { data, loading, error }] = useArchiveDiscountCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveDiscountCodeMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveDiscountCodeMutation, ArchiveDiscountCodeMutationVariables>) {
        return Apollo.useMutation<ArchiveDiscountCodeMutation, ArchiveDiscountCodeMutationVariables>(ArchiveDiscountCodeDocument, baseOptions);
      }
export type ArchiveDiscountCodeMutationHookResult = ReturnType<typeof useArchiveDiscountCodeMutation>;
export type ArchiveDiscountCodeMutationResult = Apollo.MutationResult<ArchiveDiscountCodeMutation>;
export type ArchiveDiscountCodeMutationOptions = Apollo.BaseMutationOptions<ArchiveDiscountCodeMutation, ArchiveDiscountCodeMutationVariables>;
export const CreateDiscountCodeDocument = gql`
    mutation CreateDiscountCode($name: String!, $code: String!, $discountVariant: DiscountVariant!, $deliveryOptions: [DeliveryOption!]!, $paymentMethods: [PaymentMethod!]!, $shouldAutoIncludeNewStore: Boolean!, $amount: Float!, $maximumDiscountAmount: Float!, $minimumAmountToApply: Float!, $platformFundedPercentage: Float!, $dailyLimit: Int, $totalUsageLimit: Int!, $validFrom: ISO8601DateTime!, $validUntil: ISO8601DateTime!, $storeDiscountCodes: [StoreDiscountCodesInput!]) {
  createDiscountCode(
    input: {name: $name, code: $code, discountVariant: $discountVariant, deliveryOptions: $deliveryOptions, paymentMethods: $paymentMethods, shouldAutoIncludeNewStore: $shouldAutoIncludeNewStore, amount: $amount, maximumDiscountAmount: $maximumDiscountAmount, minimumAmountToApply: $minimumAmountToApply, platformFundedPercentage: $platformFundedPercentage, dailyLimit: $dailyLimit, totalUsageLimit: $totalUsageLimit, validFrom: $validFrom, validUntil: $validUntil, storeDiscountCodes: $storeDiscountCodes}
  ) {
    discountCode {
      id
      name
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateDiscountCodeMutationFn = Apollo.MutationFunction<CreateDiscountCodeMutation, CreateDiscountCodeMutationVariables>;

/**
 * __useCreateDiscountCodeMutation__
 *
 * To run a mutation, you first call `useCreateDiscountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiscountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiscountCodeMutation, { data, loading, error }] = useCreateDiscountCodeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      discountVariant: // value for 'discountVariant'
 *      deliveryOptions: // value for 'deliveryOptions'
 *      paymentMethods: // value for 'paymentMethods'
 *      shouldAutoIncludeNewStore: // value for 'shouldAutoIncludeNewStore'
 *      amount: // value for 'amount'
 *      maximumDiscountAmount: // value for 'maximumDiscountAmount'
 *      minimumAmountToApply: // value for 'minimumAmountToApply'
 *      platformFundedPercentage: // value for 'platformFundedPercentage'
 *      dailyLimit: // value for 'dailyLimit'
 *      totalUsageLimit: // value for 'totalUsageLimit'
 *      validFrom: // value for 'validFrom'
 *      validUntil: // value for 'validUntil'
 *      storeDiscountCodes: // value for 'storeDiscountCodes'
 *   },
 * });
 */
export function useCreateDiscountCodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateDiscountCodeMutation, CreateDiscountCodeMutationVariables>) {
        return Apollo.useMutation<CreateDiscountCodeMutation, CreateDiscountCodeMutationVariables>(CreateDiscountCodeDocument, baseOptions);
      }
export type CreateDiscountCodeMutationHookResult = ReturnType<typeof useCreateDiscountCodeMutation>;
export type CreateDiscountCodeMutationResult = Apollo.MutationResult<CreateDiscountCodeMutation>;
export type CreateDiscountCodeMutationOptions = Apollo.BaseMutationOptions<CreateDiscountCodeMutation, CreateDiscountCodeMutationVariables>;
export const ToggleDiscountCodeDocument = gql`
    mutation ToggleDiscountCode($id: ID!) {
  toggleDiscountCode(id: $id) {
    discountCode {
      id
      state
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type ToggleDiscountCodeMutationFn = Apollo.MutationFunction<ToggleDiscountCodeMutation, ToggleDiscountCodeMutationVariables>;

/**
 * __useToggleDiscountCodeMutation__
 *
 * To run a mutation, you first call `useToggleDiscountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleDiscountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleDiscountCodeMutation, { data, loading, error }] = useToggleDiscountCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleDiscountCodeMutation(baseOptions?: Apollo.MutationHookOptions<ToggleDiscountCodeMutation, ToggleDiscountCodeMutationVariables>) {
        return Apollo.useMutation<ToggleDiscountCodeMutation, ToggleDiscountCodeMutationVariables>(ToggleDiscountCodeDocument, baseOptions);
      }
export type ToggleDiscountCodeMutationHookResult = ReturnType<typeof useToggleDiscountCodeMutation>;
export type ToggleDiscountCodeMutationResult = Apollo.MutationResult<ToggleDiscountCodeMutation>;
export type ToggleDiscountCodeMutationOptions = Apollo.BaseMutationOptions<ToggleDiscountCodeMutation, ToggleDiscountCodeMutationVariables>;
export const UpdateDiscountCodeDocument = gql`
    mutation UpdateDiscountCode($id: ID!, $name: String, $code: String, $discountVariant: DiscountVariant, $deliveryOptions: [DeliveryOption!], $paymentMethods: [PaymentMethod!], $shouldAutoIncludeNewStore: Boolean, $amount: Float, $maximumDiscountAmount: Float, $minimumAmountToApply: Float, $platformFundedPercentage: Float, $dailyLimit: Int, $totalUsageLimit: Int, $validFrom: ISO8601DateTime, $validUntil: ISO8601DateTime, $storeDiscountCodes: [StoreDiscountCodesInput!]) {
  updateDiscountCode(
    id: $id
    input: {name: $name, code: $code, discountVariant: $discountVariant, paymentMethods: $paymentMethods, deliveryOptions: $deliveryOptions, shouldAutoIncludeNewStore: $shouldAutoIncludeNewStore, amount: $amount, maximumDiscountAmount: $maximumDiscountAmount, minimumAmountToApply: $minimumAmountToApply, platformFundedPercentage: $platformFundedPercentage, dailyLimit: $dailyLimit, totalUsageLimit: $totalUsageLimit, validFrom: $validFrom, validUntil: $validUntil, storeDiscountCodes: $storeDiscountCodes}
  ) {
    discountCode {
      id
      name
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateDiscountCodeMutationFn = Apollo.MutationFunction<UpdateDiscountCodeMutation, UpdateDiscountCodeMutationVariables>;

/**
 * __useUpdateDiscountCodeMutation__
 *
 * To run a mutation, you first call `useUpdateDiscountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscountCodeMutation, { data, loading, error }] = useUpdateDiscountCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      discountVariant: // value for 'discountVariant'
 *      deliveryOptions: // value for 'deliveryOptions'
 *      paymentMethods: // value for 'paymentMethods'
 *      shouldAutoIncludeNewStore: // value for 'shouldAutoIncludeNewStore'
 *      amount: // value for 'amount'
 *      maximumDiscountAmount: // value for 'maximumDiscountAmount'
 *      minimumAmountToApply: // value for 'minimumAmountToApply'
 *      platformFundedPercentage: // value for 'platformFundedPercentage'
 *      dailyLimit: // value for 'dailyLimit'
 *      totalUsageLimit: // value for 'totalUsageLimit'
 *      validFrom: // value for 'validFrom'
 *      validUntil: // value for 'validUntil'
 *      storeDiscountCodes: // value for 'storeDiscountCodes'
 *   },
 * });
 */
export function useUpdateDiscountCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDiscountCodeMutation, UpdateDiscountCodeMutationVariables>) {
        return Apollo.useMutation<UpdateDiscountCodeMutation, UpdateDiscountCodeMutationVariables>(UpdateDiscountCodeDocument, baseOptions);
      }
export type UpdateDiscountCodeMutationHookResult = ReturnType<typeof useUpdateDiscountCodeMutation>;
export type UpdateDiscountCodeMutationResult = Apollo.MutationResult<UpdateDiscountCodeMutation>;
export type UpdateDiscountCodeMutationOptions = Apollo.BaseMutationOptions<UpdateDiscountCodeMutation, UpdateDiscountCodeMutationVariables>;
export const CreateMixinPromoDocument = gql`
    mutation CreateMixinPromo($outletIds: [ID!], $storesCount: Int!, $amount: Int!, $category: MixinPromoCategory!, $minSpend: Int!, $capped: Int!) {
  createMixinPromo(
    input: {outletIds: $outletIds, storesCount: $storesCount, amount: $amount, category: $category, minSpend: $minSpend, capped: $capped}
  ) {
    mixinPromo {
      outletIds
      storesCount
      amount
      category
      minSpend
      capped
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateMixinPromoMutationFn = Apollo.MutationFunction<CreateMixinPromoMutation, CreateMixinPromoMutationVariables>;

/**
 * __useCreateMixinPromoMutation__
 *
 * To run a mutation, you first call `useCreateMixinPromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMixinPromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMixinPromoMutation, { data, loading, error }] = useCreateMixinPromoMutation({
 *   variables: {
 *      outletIds: // value for 'outletIds'
 *      storesCount: // value for 'storesCount'
 *      amount: // value for 'amount'
 *      category: // value for 'category'
 *      minSpend: // value for 'minSpend'
 *      capped: // value for 'capped'
 *   },
 * });
 */
export function useCreateMixinPromoMutation(baseOptions?: Apollo.MutationHookOptions<CreateMixinPromoMutation, CreateMixinPromoMutationVariables>) {
        return Apollo.useMutation<CreateMixinPromoMutation, CreateMixinPromoMutationVariables>(CreateMixinPromoDocument, baseOptions);
      }
export type CreateMixinPromoMutationHookResult = ReturnType<typeof useCreateMixinPromoMutation>;
export type CreateMixinPromoMutationResult = Apollo.MutationResult<CreateMixinPromoMutation>;
export type CreateMixinPromoMutationOptions = Apollo.BaseMutationOptions<CreateMixinPromoMutation, CreateMixinPromoMutationVariables>;
export const RemoveMixinPromoDocument = gql`
    mutation RemoveMixinPromo($id: ID!) {
  removeMixinPromo(id: $id) {
    mixinPromo {
      id
      storesCount
      amount
      category
      minSpend
      capped
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemoveMixinPromoMutationFn = Apollo.MutationFunction<RemoveMixinPromoMutation, RemoveMixinPromoMutationVariables>;

/**
 * __useRemoveMixinPromoMutation__
 *
 * To run a mutation, you first call `useRemoveMixinPromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMixinPromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMixinPromoMutation, { data, loading, error }] = useRemoveMixinPromoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMixinPromoMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMixinPromoMutation, RemoveMixinPromoMutationVariables>) {
        return Apollo.useMutation<RemoveMixinPromoMutation, RemoveMixinPromoMutationVariables>(RemoveMixinPromoDocument, baseOptions);
      }
export type RemoveMixinPromoMutationHookResult = ReturnType<typeof useRemoveMixinPromoMutation>;
export type RemoveMixinPromoMutationResult = Apollo.MutationResult<RemoveMixinPromoMutation>;
export type RemoveMixinPromoMutationOptions = Apollo.BaseMutationOptions<RemoveMixinPromoMutation, RemoveMixinPromoMutationVariables>;
export const PushNotificationDocument = gql`
    mutation PushNotification($title: String!, $body: String!) {
  pushNotification(input: {title: $title, body: $body}) {
    success
  }
}
    `;
export type PushNotificationMutationFn = Apollo.MutationFunction<PushNotificationMutation, PushNotificationMutationVariables>;

/**
 * __usePushNotificationMutation__
 *
 * To run a mutation, you first call `usePushNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushNotificationMutation, { data, loading, error }] = usePushNotificationMutation({
 *   variables: {
 *      title: // value for 'title'
 *      body: // value for 'body'
 *   },
 * });
 */
export function usePushNotificationMutation(baseOptions?: Apollo.MutationHookOptions<PushNotificationMutation, PushNotificationMutationVariables>) {
        return Apollo.useMutation<PushNotificationMutation, PushNotificationMutationVariables>(PushNotificationDocument, baseOptions);
      }
export type PushNotificationMutationHookResult = ReturnType<typeof usePushNotificationMutation>;
export type PushNotificationMutationResult = Apollo.MutationResult<PushNotificationMutation>;
export type PushNotificationMutationOptions = Apollo.BaseMutationOptions<PushNotificationMutation, PushNotificationMutationVariables>;
export const CompleteOrderDocument = gql`
    mutation CompleteOrder($id: ID!) {
  completeOrder(id: $id) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CompleteOrderMutationFn = Apollo.MutationFunction<CompleteOrderMutation, CompleteOrderMutationVariables>;

/**
 * __useCompleteOrderMutation__
 *
 * To run a mutation, you first call `useCompleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeOrderMutation, { data, loading, error }] = useCompleteOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteOrderMutation(baseOptions?: Apollo.MutationHookOptions<CompleteOrderMutation, CompleteOrderMutationVariables>) {
        return Apollo.useMutation<CompleteOrderMutation, CompleteOrderMutationVariables>(CompleteOrderDocument, baseOptions);
      }
export type CompleteOrderMutationHookResult = ReturnType<typeof useCompleteOrderMutation>;
export type CompleteOrderMutationResult = Apollo.MutationResult<CompleteOrderMutation>;
export type CompleteOrderMutationOptions = Apollo.BaseMutationOptions<CompleteOrderMutation, CompleteOrderMutationVariables>;
export const CreateOutletDocument = gql`
    mutation CreateOutlet($name: String!, $address: String!, $latitude: Float!, $longitude: Float!, $open: String!, $close: String!, $image: File, $slides: [File!], $slidesUrls: [String!], $hide: Boolean) {
  createOutlet(
    input: {name: $name, address: $address, latitude: $latitude, longitude: $longitude, open: $open, close: $close, image: $image, slides: $slides, slidesUrls: $slidesUrls, hide: $hide}
  ) {
    outlet {
      id
      name
      address
      open
      close
      latitude
      longitude
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateOutletMutationFn = Apollo.MutationFunction<CreateOutletMutation, CreateOutletMutationVariables>;

/**
 * __useCreateOutletMutation__
 *
 * To run a mutation, you first call `useCreateOutletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOutletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOutletMutation, { data, loading, error }] = useCreateOutletMutation({
 *   variables: {
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      open: // value for 'open'
 *      close: // value for 'close'
 *      image: // value for 'image'
 *      slides: // value for 'slides'
 *      slidesUrls: // value for 'slidesUrls'
 *      hide: // value for 'hide'
 *   },
 * });
 */
export function useCreateOutletMutation(baseOptions?: Apollo.MutationHookOptions<CreateOutletMutation, CreateOutletMutationVariables>) {
        return Apollo.useMutation<CreateOutletMutation, CreateOutletMutationVariables>(CreateOutletDocument, baseOptions);
      }
export type CreateOutletMutationHookResult = ReturnType<typeof useCreateOutletMutation>;
export type CreateOutletMutationResult = Apollo.MutationResult<CreateOutletMutation>;
export type CreateOutletMutationOptions = Apollo.BaseMutationOptions<CreateOutletMutation, CreateOutletMutationVariables>;
export const UpdateOutletDocument = gql`
    mutation UpdateOutlet($id: ID!, $name: String!, $address: String, $latitude: Float, $longitude: Float, $open: String, $close: String, $image: File, $slides: [File!], $slidesUrls: [String!], $hide: Boolean, $cabinetSlots: [Int!]) {
  updateOutlet(
    id: $id
    input: {name: $name, address: $address, latitude: $latitude, longitude: $longitude, open: $open, close: $close, image: $image, slides: $slides, slidesUrls: $slidesUrls, hide: $hide, cabinetSlots: $cabinetSlots}
  ) {
    outlet {
      id
      name
      address
      open
      close
      latitude
      longitude
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateOutletMutationFn = Apollo.MutationFunction<UpdateOutletMutation, UpdateOutletMutationVariables>;

/**
 * __useUpdateOutletMutation__
 *
 * To run a mutation, you first call `useUpdateOutletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutletMutation, { data, loading, error }] = useUpdateOutletMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      open: // value for 'open'
 *      close: // value for 'close'
 *      image: // value for 'image'
 *      slides: // value for 'slides'
 *      slidesUrls: // value for 'slidesUrls'
 *      hide: // value for 'hide'
 *      cabinetSlots: // value for 'cabinetSlots'
 *   },
 * });
 */
export function useUpdateOutletMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOutletMutation, UpdateOutletMutationVariables>) {
        return Apollo.useMutation<UpdateOutletMutation, UpdateOutletMutationVariables>(UpdateOutletDocument, baseOptions);
      }
export type UpdateOutletMutationHookResult = ReturnType<typeof useUpdateOutletMutation>;
export type UpdateOutletMutationResult = Apollo.MutationResult<UpdateOutletMutation>;
export type UpdateOutletMutationOptions = Apollo.BaseMutationOptions<UpdateOutletMutation, UpdateOutletMutationVariables>;
export const DeleteMainPopUpImageDocument = gql`
    mutation DeleteMainPopUpImage {
  deleteMainPopUpImage {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type DeleteMainPopUpImageMutationFn = Apollo.MutationFunction<DeleteMainPopUpImageMutation, DeleteMainPopUpImageMutationVariables>;

/**
 * __useDeleteMainPopUpImageMutation__
 *
 * To run a mutation, you first call `useDeleteMainPopUpImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMainPopUpImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMainPopUpImageMutation, { data, loading, error }] = useDeleteMainPopUpImageMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteMainPopUpImageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMainPopUpImageMutation, DeleteMainPopUpImageMutationVariables>) {
        return Apollo.useMutation<DeleteMainPopUpImageMutation, DeleteMainPopUpImageMutationVariables>(DeleteMainPopUpImageDocument, baseOptions);
      }
export type DeleteMainPopUpImageMutationHookResult = ReturnType<typeof useDeleteMainPopUpImageMutation>;
export type DeleteMainPopUpImageMutationResult = Apollo.MutationResult<DeleteMainPopUpImageMutation>;
export type DeleteMainPopUpImageMutationOptions = Apollo.BaseMutationOptions<DeleteMainPopUpImageMutation, DeleteMainPopUpImageMutationVariables>;
export const DeleteOutletPopUpImageDocument = gql`
    mutation DeleteOutletPopUpImage($outletId: ID!) {
  deleteOutletPopUpImage(outletId: $outletId) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type DeleteOutletPopUpImageMutationFn = Apollo.MutationFunction<DeleteOutletPopUpImageMutation, DeleteOutletPopUpImageMutationVariables>;

/**
 * __useDeleteOutletPopUpImageMutation__
 *
 * To run a mutation, you first call `useDeleteOutletPopUpImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOutletPopUpImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOutletPopUpImageMutation, { data, loading, error }] = useDeleteOutletPopUpImageMutation({
 *   variables: {
 *      outletId: // value for 'outletId'
 *   },
 * });
 */
export function useDeleteOutletPopUpImageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOutletPopUpImageMutation, DeleteOutletPopUpImageMutationVariables>) {
        return Apollo.useMutation<DeleteOutletPopUpImageMutation, DeleteOutletPopUpImageMutationVariables>(DeleteOutletPopUpImageDocument, baseOptions);
      }
export type DeleteOutletPopUpImageMutationHookResult = ReturnType<typeof useDeleteOutletPopUpImageMutation>;
export type DeleteOutletPopUpImageMutationResult = Apollo.MutationResult<DeleteOutletPopUpImageMutation>;
export type DeleteOutletPopUpImageMutationOptions = Apollo.BaseMutationOptions<DeleteOutletPopUpImageMutation, DeleteOutletPopUpImageMutationVariables>;
export const UpdateOutletPopUpImageDocument = gql`
    mutation UpdateOutletPopUpImage($outletId: ID!, $image: File!) {
  updateOutletPopUpImage(outletId: $outletId, image: $image) {
    outlet {
      id
      popUpImage
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateOutletPopUpImageMutationFn = Apollo.MutationFunction<UpdateOutletPopUpImageMutation, UpdateOutletPopUpImageMutationVariables>;

/**
 * __useUpdateOutletPopUpImageMutation__
 *
 * To run a mutation, you first call `useUpdateOutletPopUpImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutletPopUpImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutletPopUpImageMutation, { data, loading, error }] = useUpdateOutletPopUpImageMutation({
 *   variables: {
 *      outletId: // value for 'outletId'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUpdateOutletPopUpImageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOutletPopUpImageMutation, UpdateOutletPopUpImageMutationVariables>) {
        return Apollo.useMutation<UpdateOutletPopUpImageMutation, UpdateOutletPopUpImageMutationVariables>(UpdateOutletPopUpImageDocument, baseOptions);
      }
export type UpdateOutletPopUpImageMutationHookResult = ReturnType<typeof useUpdateOutletPopUpImageMutation>;
export type UpdateOutletPopUpImageMutationResult = Apollo.MutationResult<UpdateOutletPopUpImageMutation>;
export type UpdateOutletPopUpImageMutationOptions = Apollo.BaseMutationOptions<UpdateOutletPopUpImageMutation, UpdateOutletPopUpImageMutationVariables>;
export const UpdateMainPopUpImageDocument = gql`
    mutation UpdateMainPopUpImage($image: File!) {
  updateMainPopUpImage(image: $image) {
    popUpImage {
      id
      image
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateMainPopUpImageMutationFn = Apollo.MutationFunction<UpdateMainPopUpImageMutation, UpdateMainPopUpImageMutationVariables>;

/**
 * __useUpdateMainPopUpImageMutation__
 *
 * To run a mutation, you first call `useUpdateMainPopUpImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMainPopUpImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMainPopUpImageMutation, { data, loading, error }] = useUpdateMainPopUpImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUpdateMainPopUpImageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMainPopUpImageMutation, UpdateMainPopUpImageMutationVariables>) {
        return Apollo.useMutation<UpdateMainPopUpImageMutation, UpdateMainPopUpImageMutationVariables>(UpdateMainPopUpImageDocument, baseOptions);
      }
export type UpdateMainPopUpImageMutationHookResult = ReturnType<typeof useUpdateMainPopUpImageMutation>;
export type UpdateMainPopUpImageMutationResult = Apollo.MutationResult<UpdateMainPopUpImageMutation>;
export type UpdateMainPopUpImageMutationOptions = Apollo.BaseMutationOptions<UpdateMainPopUpImageMutation, UpdateMainPopUpImageMutationVariables>;
export const EmailResetPasswordUrlDocument = gql`
    mutation EmailResetPasswordUrl($email: String!) {
  emailResetPasswordUrl(email: $email) {
    success
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type EmailResetPasswordUrlMutationFn = Apollo.MutationFunction<EmailResetPasswordUrlMutation, EmailResetPasswordUrlMutationVariables>;

/**
 * __useEmailResetPasswordUrlMutation__
 *
 * To run a mutation, you first call `useEmailResetPasswordUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailResetPasswordUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailResetPasswordUrlMutation, { data, loading, error }] = useEmailResetPasswordUrlMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useEmailResetPasswordUrlMutation(baseOptions?: Apollo.MutationHookOptions<EmailResetPasswordUrlMutation, EmailResetPasswordUrlMutationVariables>) {
        return Apollo.useMutation<EmailResetPasswordUrlMutation, EmailResetPasswordUrlMutationVariables>(EmailResetPasswordUrlDocument, baseOptions);
      }
export type EmailResetPasswordUrlMutationHookResult = ReturnType<typeof useEmailResetPasswordUrlMutation>;
export type EmailResetPasswordUrlMutationResult = Apollo.MutationResult<EmailResetPasswordUrlMutation>;
export type EmailResetPasswordUrlMutationOptions = Apollo.BaseMutationOptions<EmailResetPasswordUrlMutation, EmailResetPasswordUrlMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $password: String!, $passwordConfirmation: String!) {
  resetPassword(
    input: {token: $token, password: $password, passwordConfirmation: $passwordConfirmation}
  ) {
    user {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const CreateRestaurantDocument = gql`
    mutation CreateRestaurant($name: String!, $number: String, $outletId: ID!, $taxPercentage: Float!, $counterFeePercentage: Float!, $techFeePercentage: Float!, $revenueSharePercentage: Float!) {
  createRestaurant(
    input: {name: $name, number: $number, outletId: $outletId, taxPercentage: $taxPercentage, counterFeePercentage: $counterFeePercentage, techFeePercentage: $techFeePercentage, revenueSharePercentage: $revenueSharePercentage}
  ) {
    restaurant {
      id
      name
      number
      taxPercentage
      counterFeePercentage
      techFeePercentage
      revenueSharePercentage
      outlet {
        id
        name
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateRestaurantMutationFn = Apollo.MutationFunction<CreateRestaurantMutation, CreateRestaurantMutationVariables>;

/**
 * __useCreateRestaurantMutation__
 *
 * To run a mutation, you first call `useCreateRestaurantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRestaurantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRestaurantMutation, { data, loading, error }] = useCreateRestaurantMutation({
 *   variables: {
 *      name: // value for 'name'
 *      number: // value for 'number'
 *      outletId: // value for 'outletId'
 *      taxPercentage: // value for 'taxPercentage'
 *      counterFeePercentage: // value for 'counterFeePercentage'
 *      techFeePercentage: // value for 'techFeePercentage'
 *      revenueSharePercentage: // value for 'revenueSharePercentage'
 *   },
 * });
 */
export function useCreateRestaurantMutation(baseOptions?: Apollo.MutationHookOptions<CreateRestaurantMutation, CreateRestaurantMutationVariables>) {
        return Apollo.useMutation<CreateRestaurantMutation, CreateRestaurantMutationVariables>(CreateRestaurantDocument, baseOptions);
      }
export type CreateRestaurantMutationHookResult = ReturnType<typeof useCreateRestaurantMutation>;
export type CreateRestaurantMutationResult = Apollo.MutationResult<CreateRestaurantMutation>;
export type CreateRestaurantMutationOptions = Apollo.BaseMutationOptions<CreateRestaurantMutation, CreateRestaurantMutationVariables>;
export const UpdateRestaurantDocument = gql`
    mutation UpdateRestaurant($id: ID!, $name: String, $number: String, $cabinetSlots: [Int!], $outletId: ID!, $taxPercentage: Float, $counterFeePercentage: Float, $techFeePercentage: Float, $revenueSharePercentage: Float, $revenueSharePercentageNew: Float, $revenueSharePercentageNewEffectiveDate: ISO8601DateTime) {
  updateRestaurant(
    id: $id
    input: {name: $name, number: $number, cabinetSlots: $cabinetSlots, outletId: $outletId, taxPercentage: $taxPercentage, counterFeePercentage: $counterFeePercentage, techFeePercentage: $techFeePercentage, revenueSharePercentage: $revenueSharePercentage, revenueSharePercentageNew: $revenueSharePercentageNew, revenueSharePercentageNewEffectiveDate: $revenueSharePercentageNewEffectiveDate}
  ) {
    restaurant {
      id
      name
      number
      taxPercentage
      counterFeePercentage
      techFeePercentage
      revenueSharePercentage
      revenueSharePercentageNew
      revenueSharePercentageNewEffectiveDate
      outlet {
        id
        name
      }
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateRestaurantMutationFn = Apollo.MutationFunction<UpdateRestaurantMutation, UpdateRestaurantMutationVariables>;

/**
 * __useUpdateRestaurantMutation__
 *
 * To run a mutation, you first call `useUpdateRestaurantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestaurantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRestaurantMutation, { data, loading, error }] = useUpdateRestaurantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      number: // value for 'number'
 *      cabinetSlots: // value for 'cabinetSlots'
 *      outletId: // value for 'outletId'
 *      taxPercentage: // value for 'taxPercentage'
 *      counterFeePercentage: // value for 'counterFeePercentage'
 *      techFeePercentage: // value for 'techFeePercentage'
 *      revenueSharePercentage: // value for 'revenueSharePercentage'
 *      revenueSharePercentageNew: // value for 'revenueSharePercentageNew'
 *      revenueSharePercentageNewEffectiveDate: // value for 'revenueSharePercentageNewEffectiveDate'
 *   },
 * });
 */
export function useUpdateRestaurantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRestaurantMutation, UpdateRestaurantMutationVariables>) {
        return Apollo.useMutation<UpdateRestaurantMutation, UpdateRestaurantMutationVariables>(UpdateRestaurantDocument, baseOptions);
      }
export type UpdateRestaurantMutationHookResult = ReturnType<typeof useUpdateRestaurantMutation>;
export type UpdateRestaurantMutationResult = Apollo.MutationResult<UpdateRestaurantMutation>;
export type UpdateRestaurantMutationOptions = Apollo.BaseMutationOptions<UpdateRestaurantMutation, UpdateRestaurantMutationVariables>;
export const CreateRestaurantAdminDocument = gql`
    mutation CreateRestaurantAdmin($email: String!, $password: String!, $phoneNumber: String!, $isCashier: Boolean!, $storeIds: [ID!]) {
  createRestaurantAdmin(
    input: {email: $email, password: $password, phoneNumber: $phoneNumber, isCashier: $isCashier, storeIds: $storeIds}
  ) {
    restaurantAdmin {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateRestaurantAdminMutationFn = Apollo.MutationFunction<CreateRestaurantAdminMutation, CreateRestaurantAdminMutationVariables>;

/**
 * __useCreateRestaurantAdminMutation__
 *
 * To run a mutation, you first call `useCreateRestaurantAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRestaurantAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRestaurantAdminMutation, { data, loading, error }] = useCreateRestaurantAdminMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      phoneNumber: // value for 'phoneNumber'
 *      isCashier: // value for 'isCashier'
 *      storeIds: // value for 'storeIds'
 *   },
 * });
 */
export function useCreateRestaurantAdminMutation(baseOptions?: Apollo.MutationHookOptions<CreateRestaurantAdminMutation, CreateRestaurantAdminMutationVariables>) {
        return Apollo.useMutation<CreateRestaurantAdminMutation, CreateRestaurantAdminMutationVariables>(CreateRestaurantAdminDocument, baseOptions);
      }
export type CreateRestaurantAdminMutationHookResult = ReturnType<typeof useCreateRestaurantAdminMutation>;
export type CreateRestaurantAdminMutationResult = Apollo.MutationResult<CreateRestaurantAdminMutation>;
export type CreateRestaurantAdminMutationOptions = Apollo.BaseMutationOptions<CreateRestaurantAdminMutation, CreateRestaurantAdminMutationVariables>;
export const UpdateRestaurantAdminDocument = gql`
    mutation UpdateRestaurantAdmin($id: ID!, $email: String, $phoneNumber: String, $isCashier: Boolean!, $storeIds: [ID!]) {
  updateRestaurantAdmin(
    id: $id
    input: {email: $email, phoneNumber: $phoneNumber, isCashier: $isCashier, storeIds: $storeIds}
  ) {
    restaurantAdmin {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateRestaurantAdminMutationFn = Apollo.MutationFunction<UpdateRestaurantAdminMutation, UpdateRestaurantAdminMutationVariables>;

/**
 * __useUpdateRestaurantAdminMutation__
 *
 * To run a mutation, you first call `useUpdateRestaurantAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestaurantAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRestaurantAdminMutation, { data, loading, error }] = useUpdateRestaurantAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      isCashier: // value for 'isCashier'
 *      storeIds: // value for 'storeIds'
 *   },
 * });
 */
export function useUpdateRestaurantAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRestaurantAdminMutation, UpdateRestaurantAdminMutationVariables>) {
        return Apollo.useMutation<UpdateRestaurantAdminMutation, UpdateRestaurantAdminMutationVariables>(UpdateRestaurantAdminDocument, baseOptions);
      }
export type UpdateRestaurantAdminMutationHookResult = ReturnType<typeof useUpdateRestaurantAdminMutation>;
export type UpdateRestaurantAdminMutationResult = Apollo.MutationResult<UpdateRestaurantAdminMutation>;
export type UpdateRestaurantAdminMutationOptions = Apollo.BaseMutationOptions<UpdateRestaurantAdminMutation, UpdateRestaurantAdminMutationVariables>;
export const CreateRestaurantUserDocument = gql`
    mutation CreateRestaurantUser($email: String!, $password: String!, $phoneNumber: String!, $isCashier: Boolean!, $role: RestaurantUserRole!, $restaurantId: ID!, $storeIds: [ID!], $isBanned: Boolean!) {
  createRestaurantUser(
    input: {email: $email, password: $password, phoneNumber: $phoneNumber, isCashier: $isCashier, role: $role, restaurantId: $restaurantId, storeIds: $storeIds, isBanned: $isBanned}
  ) {
    restaurantUser {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type CreateRestaurantUserMutationFn = Apollo.MutationFunction<CreateRestaurantUserMutation, CreateRestaurantUserMutationVariables>;

/**
 * __useCreateRestaurantUserMutation__
 *
 * To run a mutation, you first call `useCreateRestaurantUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRestaurantUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRestaurantUserMutation, { data, loading, error }] = useCreateRestaurantUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      phoneNumber: // value for 'phoneNumber'
 *      isCashier: // value for 'isCashier'
 *      role: // value for 'role'
 *      restaurantId: // value for 'restaurantId'
 *      storeIds: // value for 'storeIds'
 *      isBanned: // value for 'isBanned'
 *   },
 * });
 */
export function useCreateRestaurantUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateRestaurantUserMutation, CreateRestaurantUserMutationVariables>) {
        return Apollo.useMutation<CreateRestaurantUserMutation, CreateRestaurantUserMutationVariables>(CreateRestaurantUserDocument, baseOptions);
      }
export type CreateRestaurantUserMutationHookResult = ReturnType<typeof useCreateRestaurantUserMutation>;
export type CreateRestaurantUserMutationResult = Apollo.MutationResult<CreateRestaurantUserMutation>;
export type CreateRestaurantUserMutationOptions = Apollo.BaseMutationOptions<CreateRestaurantUserMutation, CreateRestaurantUserMutationVariables>;
export const RemoveRestaurantUserDocument = gql`
    mutation RemoveRestaurantUser($id: ID!) {
  removeRestaurantUser(id: $id) {
    restaurantUser {
      id
      email
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RemoveRestaurantUserMutationFn = Apollo.MutationFunction<RemoveRestaurantUserMutation, RemoveRestaurantUserMutationVariables>;

/**
 * __useRemoveRestaurantUserMutation__
 *
 * To run a mutation, you first call `useRemoveRestaurantUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRestaurantUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRestaurantUserMutation, { data, loading, error }] = useRemoveRestaurantUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRestaurantUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRestaurantUserMutation, RemoveRestaurantUserMutationVariables>) {
        return Apollo.useMutation<RemoveRestaurantUserMutation, RemoveRestaurantUserMutationVariables>(RemoveRestaurantUserDocument, baseOptions);
      }
export type RemoveRestaurantUserMutationHookResult = ReturnType<typeof useRemoveRestaurantUserMutation>;
export type RemoveRestaurantUserMutationResult = Apollo.MutationResult<RemoveRestaurantUserMutation>;
export type RemoveRestaurantUserMutationOptions = Apollo.BaseMutationOptions<RemoveRestaurantUserMutation, RemoveRestaurantUserMutationVariables>;
export const UpdateRestaurantUserDocument = gql`
    mutation UpdateRestaurantUser($id: ID!, $email: String, $phoneNumber: String, $isCashier: Boolean, $role: RestaurantUserRole, $storeIds: [ID!], $isBanned: Boolean!) {
  updateRestaurantUser(
    id: $id
    input: {email: $email, phoneNumber: $phoneNumber, isCashier: $isCashier, role: $role, storeIds: $storeIds, isBanned: $isBanned}
  ) {
    restaurantUser {
      id
    }
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateRestaurantUserMutationFn = Apollo.MutationFunction<UpdateRestaurantUserMutation, UpdateRestaurantUserMutationVariables>;

/**
 * __useUpdateRestaurantUserMutation__
 *
 * To run a mutation, you first call `useUpdateRestaurantUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestaurantUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRestaurantUserMutation, { data, loading, error }] = useUpdateRestaurantUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      isCashier: // value for 'isCashier'
 *      role: // value for 'role'
 *      storeIds: // value for 'storeIds'
 *      isBanned: // value for 'isBanned'
 *   },
 * });
 */
export function useUpdateRestaurantUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRestaurantUserMutation, UpdateRestaurantUserMutationVariables>) {
        return Apollo.useMutation<UpdateRestaurantUserMutation, UpdateRestaurantUserMutationVariables>(UpdateRestaurantUserDocument, baseOptions);
      }
export type UpdateRestaurantUserMutationHookResult = ReturnType<typeof useUpdateRestaurantUserMutation>;
export type UpdateRestaurantUserMutationResult = Apollo.MutationResult<UpdateRestaurantUserMutation>;
export type UpdateRestaurantUserMutationOptions = Apollo.BaseMutationOptions<UpdateRestaurantUserMutation, UpdateRestaurantUserMutationVariables>;
export const ToggleEnableMailingDocument = gql`
    mutation ToggleEnableMailing($toggle: Boolean!) {
  toggleEnableMailing(toggle: $toggle) {
    toggle
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type ToggleEnableMailingMutationFn = Apollo.MutationFunction<ToggleEnableMailingMutation, ToggleEnableMailingMutationVariables>;

/**
 * __useToggleEnableMailingMutation__
 *
 * To run a mutation, you first call `useToggleEnableMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleEnableMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleEnableMailingMutation, { data, loading, error }] = useToggleEnableMailingMutation({
 *   variables: {
 *      toggle: // value for 'toggle'
 *   },
 * });
 */
export function useToggleEnableMailingMutation(baseOptions?: Apollo.MutationHookOptions<ToggleEnableMailingMutation, ToggleEnableMailingMutationVariables>) {
        return Apollo.useMutation<ToggleEnableMailingMutation, ToggleEnableMailingMutationVariables>(ToggleEnableMailingDocument, baseOptions);
      }
export type ToggleEnableMailingMutationHookResult = ReturnType<typeof useToggleEnableMailingMutation>;
export type ToggleEnableMailingMutationResult = Apollo.MutationResult<ToggleEnableMailingMutation>;
export type ToggleEnableMailingMutationOptions = Apollo.BaseMutationOptions<ToggleEnableMailingMutation, ToggleEnableMailingMutationVariables>;
export const ToggleFirstOrderPromoDocument = gql`
    mutation ToggleFirstOrderPromo($toggle: Boolean!) {
  toggleFirstOrderPromo(toggle: $toggle) {
    toggle
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type ToggleFirstOrderPromoMutationFn = Apollo.MutationFunction<ToggleFirstOrderPromoMutation, ToggleFirstOrderPromoMutationVariables>;

/**
 * __useToggleFirstOrderPromoMutation__
 *
 * To run a mutation, you first call `useToggleFirstOrderPromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleFirstOrderPromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleFirstOrderPromoMutation, { data, loading, error }] = useToggleFirstOrderPromoMutation({
 *   variables: {
 *      toggle: // value for 'toggle'
 *   },
 * });
 */
export function useToggleFirstOrderPromoMutation(baseOptions?: Apollo.MutationHookOptions<ToggleFirstOrderPromoMutation, ToggleFirstOrderPromoMutationVariables>) {
        return Apollo.useMutation<ToggleFirstOrderPromoMutation, ToggleFirstOrderPromoMutationVariables>(ToggleFirstOrderPromoDocument, baseOptions);
      }
export type ToggleFirstOrderPromoMutationHookResult = ReturnType<typeof useToggleFirstOrderPromoMutation>;
export type ToggleFirstOrderPromoMutationResult = Apollo.MutationResult<ToggleFirstOrderPromoMutation>;
export type ToggleFirstOrderPromoMutationOptions = Apollo.BaseMutationOptions<ToggleFirstOrderPromoMutation, ToggleFirstOrderPromoMutationVariables>;
export const UpdateBookingViewingEmailsDocument = gql`
    mutation UpdateBookingViewingEmails($emails: [String!]!) {
  updateBookingViewingEmails(emails: $emails) {
    emails
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateBookingViewingEmailsMutationFn = Apollo.MutationFunction<UpdateBookingViewingEmailsMutation, UpdateBookingViewingEmailsMutationVariables>;

/**
 * __useUpdateBookingViewingEmailsMutation__
 *
 * To run a mutation, you first call `useUpdateBookingViewingEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingViewingEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingViewingEmailsMutation, { data, loading, error }] = useUpdateBookingViewingEmailsMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useUpdateBookingViewingEmailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingViewingEmailsMutation, UpdateBookingViewingEmailsMutationVariables>) {
        return Apollo.useMutation<UpdateBookingViewingEmailsMutation, UpdateBookingViewingEmailsMutationVariables>(UpdateBookingViewingEmailsDocument, baseOptions);
      }
export type UpdateBookingViewingEmailsMutationHookResult = ReturnType<typeof useUpdateBookingViewingEmailsMutation>;
export type UpdateBookingViewingEmailsMutationResult = Apollo.MutationResult<UpdateBookingViewingEmailsMutation>;
export type UpdateBookingViewingEmailsMutationOptions = Apollo.BaseMutationOptions<UpdateBookingViewingEmailsMutation, UpdateBookingViewingEmailsMutationVariables>;
export const UpdateConsumerAnnouncementDocument = gql`
    mutation UpdateConsumerAnnouncement($announcement: String!) {
  updateConsumerAnnouncement(announcement: $announcement) {
    announcement
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateConsumerAnnouncementMutationFn = Apollo.MutationFunction<UpdateConsumerAnnouncementMutation, UpdateConsumerAnnouncementMutationVariables>;

/**
 * __useUpdateConsumerAnnouncementMutation__
 *
 * To run a mutation, you first call `useUpdateConsumerAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsumerAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsumerAnnouncementMutation, { data, loading, error }] = useUpdateConsumerAnnouncementMutation({
 *   variables: {
 *      announcement: // value for 'announcement'
 *   },
 * });
 */
export function useUpdateConsumerAnnouncementMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConsumerAnnouncementMutation, UpdateConsumerAnnouncementMutationVariables>) {
        return Apollo.useMutation<UpdateConsumerAnnouncementMutation, UpdateConsumerAnnouncementMutationVariables>(UpdateConsumerAnnouncementDocument, baseOptions);
      }
export type UpdateConsumerAnnouncementMutationHookResult = ReturnType<typeof useUpdateConsumerAnnouncementMutation>;
export type UpdateConsumerAnnouncementMutationResult = Apollo.MutationResult<UpdateConsumerAnnouncementMutation>;
export type UpdateConsumerAnnouncementMutationOptions = Apollo.BaseMutationOptions<UpdateConsumerAnnouncementMutation, UpdateConsumerAnnouncementMutationVariables>;
export const UpdateKitchenAppVersionDocument = gql`
    mutation UpdateKitchenAppVersion($version: String!, $downloadUrl: String!) {
  updateKitchenAppVersion(version: $version, downloadUrl: $downloadUrl) {
    version
    downloadUrl
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateKitchenAppVersionMutationFn = Apollo.MutationFunction<UpdateKitchenAppVersionMutation, UpdateKitchenAppVersionMutationVariables>;

/**
 * __useUpdateKitchenAppVersionMutation__
 *
 * To run a mutation, you first call `useUpdateKitchenAppVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKitchenAppVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKitchenAppVersionMutation, { data, loading, error }] = useUpdateKitchenAppVersionMutation({
 *   variables: {
 *      version: // value for 'version'
 *      downloadUrl: // value for 'downloadUrl'
 *   },
 * });
 */
export function useUpdateKitchenAppVersionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKitchenAppVersionMutation, UpdateKitchenAppVersionMutationVariables>) {
        return Apollo.useMutation<UpdateKitchenAppVersionMutation, UpdateKitchenAppVersionMutationVariables>(UpdateKitchenAppVersionDocument, baseOptions);
      }
export type UpdateKitchenAppVersionMutationHookResult = ReturnType<typeof useUpdateKitchenAppVersionMutation>;
export type UpdateKitchenAppVersionMutationResult = Apollo.MutationResult<UpdateKitchenAppVersionMutation>;
export type UpdateKitchenAppVersionMutationOptions = Apollo.BaseMutationOptions<UpdateKitchenAppVersionMutation, UpdateKitchenAppVersionMutationVariables>;
export const UpdateOperatingCountriesDocument = gql`
    mutation UpdateOperatingCountries($countries: [String!]!) {
  updateOperatingCountries(countries: $countries) {
    countries
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateOperatingCountriesMutationFn = Apollo.MutationFunction<UpdateOperatingCountriesMutation, UpdateOperatingCountriesMutationVariables>;

/**
 * __useUpdateOperatingCountriesMutation__
 *
 * To run a mutation, you first call `useUpdateOperatingCountriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOperatingCountriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOperatingCountriesMutation, { data, loading, error }] = useUpdateOperatingCountriesMutation({
 *   variables: {
 *      countries: // value for 'countries'
 *   },
 * });
 */
export function useUpdateOperatingCountriesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOperatingCountriesMutation, UpdateOperatingCountriesMutationVariables>) {
        return Apollo.useMutation<UpdateOperatingCountriesMutation, UpdateOperatingCountriesMutationVariables>(UpdateOperatingCountriesDocument, baseOptions);
      }
export type UpdateOperatingCountriesMutationHookResult = ReturnType<typeof useUpdateOperatingCountriesMutation>;
export type UpdateOperatingCountriesMutationResult = Apollo.MutationResult<UpdateOperatingCountriesMutation>;
export type UpdateOperatingCountriesMutationOptions = Apollo.BaseMutationOptions<UpdateOperatingCountriesMutation, UpdateOperatingCountriesMutationVariables>;
export const UpdateStoreAutoAssignEmailsDocument = gql`
    mutation UpdateStoreAutoAssignEmails($emails: [String!]!) {
  updateStoreAutoAssignEmails(emails: $emails) {
    emails
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateStoreAutoAssignEmailsMutationFn = Apollo.MutationFunction<UpdateStoreAutoAssignEmailsMutation, UpdateStoreAutoAssignEmailsMutationVariables>;

/**
 * __useUpdateStoreAutoAssignEmailsMutation__
 *
 * To run a mutation, you first call `useUpdateStoreAutoAssignEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreAutoAssignEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreAutoAssignEmailsMutation, { data, loading, error }] = useUpdateStoreAutoAssignEmailsMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useUpdateStoreAutoAssignEmailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreAutoAssignEmailsMutation, UpdateStoreAutoAssignEmailsMutationVariables>) {
        return Apollo.useMutation<UpdateStoreAutoAssignEmailsMutation, UpdateStoreAutoAssignEmailsMutationVariables>(UpdateStoreAutoAssignEmailsDocument, baseOptions);
      }
export type UpdateStoreAutoAssignEmailsMutationHookResult = ReturnType<typeof useUpdateStoreAutoAssignEmailsMutation>;
export type UpdateStoreAutoAssignEmailsMutationResult = Apollo.MutationResult<UpdateStoreAutoAssignEmailsMutation>;
export type UpdateStoreAutoAssignEmailsMutationOptions = Apollo.BaseMutationOptions<UpdateStoreAutoAssignEmailsMutation, UpdateStoreAutoAssignEmailsMutationVariables>;
export const UpdateSupplyBnplDocument = gql`
    mutation UpdateSupplyBnpl($percentage: Float!, $rate: String!, $durationForRate: Int!) {
  updateSupplyBnpl(
    percentage: $percentage
    rate: $rate
    durationForRate: $durationForRate
  ) {
    supplyBnpl
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type UpdateSupplyBnplMutationFn = Apollo.MutationFunction<UpdateSupplyBnplMutation, UpdateSupplyBnplMutationVariables>;

/**
 * __useUpdateSupplyBnplMutation__
 *
 * To run a mutation, you first call `useUpdateSupplyBnplMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplyBnplMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplyBnplMutation, { data, loading, error }] = useUpdateSupplyBnplMutation({
 *   variables: {
 *      percentage: // value for 'percentage'
 *      rate: // value for 'rate'
 *      durationForRate: // value for 'durationForRate'
 *   },
 * });
 */
export function useUpdateSupplyBnplMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSupplyBnplMutation, UpdateSupplyBnplMutationVariables>) {
        return Apollo.useMutation<UpdateSupplyBnplMutation, UpdateSupplyBnplMutationVariables>(UpdateSupplyBnplDocument, baseOptions);
      }
export type UpdateSupplyBnplMutationHookResult = ReturnType<typeof useUpdateSupplyBnplMutation>;
export type UpdateSupplyBnplMutationResult = Apollo.MutationResult<UpdateSupplyBnplMutation>;
export type UpdateSupplyBnplMutationOptions = Apollo.BaseMutationOptions<UpdateSupplyBnplMutation, UpdateSupplyBnplMutationVariables>;
export const CreatePaymentProofDocument = gql`
    mutation CreatePaymentProof($file: File!, $purchaseOrderId: ID!) {
  createSupplierPaymentProof(file: $file, purchaseOrderId: $purchaseOrderId) {
    purchaseOrder {
      ...SupplierPurchaseOrder
    }
    errors {
      ...Error
    }
  }
}
    ${SupplierPurchaseOrderFragmentDoc}
${ErrorFragmentDoc}`;
export type CreatePaymentProofMutationFn = Apollo.MutationFunction<CreatePaymentProofMutation, CreatePaymentProofMutationVariables>;

/**
 * __useCreatePaymentProofMutation__
 *
 * To run a mutation, you first call `useCreatePaymentProofMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentProofMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentProofMutation, { data, loading, error }] = useCreatePaymentProofMutation({
 *   variables: {
 *      file: // value for 'file'
 *      purchaseOrderId: // value for 'purchaseOrderId'
 *   },
 * });
 */
export function useCreatePaymentProofMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentProofMutation, CreatePaymentProofMutationVariables>) {
        return Apollo.useMutation<CreatePaymentProofMutation, CreatePaymentProofMutationVariables>(CreatePaymentProofDocument, baseOptions);
      }
export type CreatePaymentProofMutationHookResult = ReturnType<typeof useCreatePaymentProofMutation>;
export type CreatePaymentProofMutationResult = Apollo.MutationResult<CreatePaymentProofMutation>;
export type CreatePaymentProofMutationOptions = Apollo.BaseMutationOptions<CreatePaymentProofMutation, CreatePaymentProofMutationVariables>;
export const CreateSupplierDocument = gql`
    mutation CreateSupplier($name: String!, $email: String!, $password: String!, $phoneNumber: String!) {
  createSupplier(
    input: {name: $name, email: $email, password: $password, phoneNumber: $phoneNumber}
  ) {
    supplier {
      ...Suppliers
    }
    errors {
      ...Error
    }
  }
}
    ${SuppliersFragmentDoc}
${ErrorFragmentDoc}`;
export type CreateSupplierMutationFn = Apollo.MutationFunction<CreateSupplierMutation, CreateSupplierMutationVariables>;

/**
 * __useCreateSupplierMutation__
 *
 * To run a mutation, you first call `useCreateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierMutation, { data, loading, error }] = useCreateSupplierMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useCreateSupplierMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupplierMutation, CreateSupplierMutationVariables>) {
        return Apollo.useMutation<CreateSupplierMutation, CreateSupplierMutationVariables>(CreateSupplierDocument, baseOptions);
      }
export type CreateSupplierMutationHookResult = ReturnType<typeof useCreateSupplierMutation>;
export type CreateSupplierMutationResult = Apollo.MutationResult<CreateSupplierMutation>;
export type CreateSupplierMutationOptions = Apollo.BaseMutationOptions<CreateSupplierMutation, CreateSupplierMutationVariables>;
export const RemoveFinancingDocument = gql`
    mutation RemoveFinancing($purchaseOrderId: ID!) {
  removeSupplierFinancing(purchaseOrderId: $purchaseOrderId) {
    purchaseOrder {
      ...SupplierPurchaseOrder
    }
    errors {
      ...Error
    }
  }
}
    ${SupplierPurchaseOrderFragmentDoc}
${ErrorFragmentDoc}`;
export type RemoveFinancingMutationFn = Apollo.MutationFunction<RemoveFinancingMutation, RemoveFinancingMutationVariables>;

/**
 * __useRemoveFinancingMutation__
 *
 * To run a mutation, you first call `useRemoveFinancingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFinancingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFinancingMutation, { data, loading, error }] = useRemoveFinancingMutation({
 *   variables: {
 *      purchaseOrderId: // value for 'purchaseOrderId'
 *   },
 * });
 */
export function useRemoveFinancingMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFinancingMutation, RemoveFinancingMutationVariables>) {
        return Apollo.useMutation<RemoveFinancingMutation, RemoveFinancingMutationVariables>(RemoveFinancingDocument, baseOptions);
      }
export type RemoveFinancingMutationHookResult = ReturnType<typeof useRemoveFinancingMutation>;
export type RemoveFinancingMutationResult = Apollo.MutationResult<RemoveFinancingMutation>;
export type RemoveFinancingMutationOptions = Apollo.BaseMutationOptions<RemoveFinancingMutation, RemoveFinancingMutationVariables>;
export const AnalyticsConsumersPageDocument = gql`
    query AnalyticsConsumersPage {
  me {
    id
    role
    email
  }
  analyticsConsumersUrl
}
    `;

/**
 * __useAnalyticsConsumersPageQuery__
 *
 * To run a query within a React component, call `useAnalyticsConsumersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsConsumersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsConsumersPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalyticsConsumersPageQuery(baseOptions?: Apollo.QueryHookOptions<AnalyticsConsumersPageQuery, AnalyticsConsumersPageQueryVariables>) {
        return Apollo.useQuery<AnalyticsConsumersPageQuery, AnalyticsConsumersPageQueryVariables>(AnalyticsConsumersPageDocument, baseOptions);
      }
export function useAnalyticsConsumersPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsConsumersPageQuery, AnalyticsConsumersPageQueryVariables>) {
          return Apollo.useLazyQuery<AnalyticsConsumersPageQuery, AnalyticsConsumersPageQueryVariables>(AnalyticsConsumersPageDocument, baseOptions);
        }
export type AnalyticsConsumersPageQueryHookResult = ReturnType<typeof useAnalyticsConsumersPageQuery>;
export type AnalyticsConsumersPageLazyQueryHookResult = ReturnType<typeof useAnalyticsConsumersPageLazyQuery>;
export type AnalyticsConsumersPageQueryResult = Apollo.QueryResult<AnalyticsConsumersPageQuery, AnalyticsConsumersPageQueryVariables>;
export const CategoriesPageDocument = gql`
    query CategoriesPage {
  me {
    id
    role
    email
  }
  categories {
    id
    name
    popular
  }
}
    `;

/**
 * __useCategoriesPageQuery__
 *
 * To run a query within a React component, call `useCategoriesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesPageQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesPageQuery, CategoriesPageQueryVariables>) {
        return Apollo.useQuery<CategoriesPageQuery, CategoriesPageQueryVariables>(CategoriesPageDocument, baseOptions);
      }
export function useCategoriesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesPageQuery, CategoriesPageQueryVariables>) {
          return Apollo.useLazyQuery<CategoriesPageQuery, CategoriesPageQueryVariables>(CategoriesPageDocument, baseOptions);
        }
export type CategoriesPageQueryHookResult = ReturnType<typeof useCategoriesPageQuery>;
export type CategoriesPageLazyQueryHookResult = ReturnType<typeof useCategoriesPageLazyQuery>;
export type CategoriesPageQueryResult = Apollo.QueryResult<CategoriesPageQuery, CategoriesPageQueryVariables>;
export const UpdateDiscountCodePageDocument = gql`
    query UpdateDiscountCodePage {
  me {
    id
    role
    email
  }
  outlets {
    id
    name
  }
}
    `;

/**
 * __useUpdateDiscountCodePageQuery__
 *
 * To run a query within a React component, call `useUpdateDiscountCodePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscountCodePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateDiscountCodePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpdateDiscountCodePageQuery(baseOptions?: Apollo.QueryHookOptions<UpdateDiscountCodePageQuery, UpdateDiscountCodePageQueryVariables>) {
        return Apollo.useQuery<UpdateDiscountCodePageQuery, UpdateDiscountCodePageQueryVariables>(UpdateDiscountCodePageDocument, baseOptions);
      }
export function useUpdateDiscountCodePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateDiscountCodePageQuery, UpdateDiscountCodePageQueryVariables>) {
          return Apollo.useLazyQuery<UpdateDiscountCodePageQuery, UpdateDiscountCodePageQueryVariables>(UpdateDiscountCodePageDocument, baseOptions);
        }
export type UpdateDiscountCodePageQueryHookResult = ReturnType<typeof useUpdateDiscountCodePageQuery>;
export type UpdateDiscountCodePageLazyQueryHookResult = ReturnType<typeof useUpdateDiscountCodePageLazyQuery>;
export type UpdateDiscountCodePageQueryResult = Apollo.QueryResult<UpdateDiscountCodePageQuery, UpdateDiscountCodePageQueryVariables>;
export const CreateDiscountCodePageDocument = gql`
    query CreateDiscountCodePage {
  me {
    id
    role
    email
  }
  outlets {
    id
    name
  }
}
    `;

/**
 * __useCreateDiscountCodePageQuery__
 *
 * To run a query within a React component, call `useCreateDiscountCodePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateDiscountCodePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateDiscountCodePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateDiscountCodePageQuery(baseOptions?: Apollo.QueryHookOptions<CreateDiscountCodePageQuery, CreateDiscountCodePageQueryVariables>) {
        return Apollo.useQuery<CreateDiscountCodePageQuery, CreateDiscountCodePageQueryVariables>(CreateDiscountCodePageDocument, baseOptions);
      }
export function useCreateDiscountCodePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateDiscountCodePageQuery, CreateDiscountCodePageQueryVariables>) {
          return Apollo.useLazyQuery<CreateDiscountCodePageQuery, CreateDiscountCodePageQueryVariables>(CreateDiscountCodePageDocument, baseOptions);
        }
export type CreateDiscountCodePageQueryHookResult = ReturnType<typeof useCreateDiscountCodePageQuery>;
export type CreateDiscountCodePageLazyQueryHookResult = ReturnType<typeof useCreateDiscountCodePageLazyQuery>;
export type CreateDiscountCodePageQueryResult = Apollo.QueryResult<CreateDiscountCodePageQuery, CreateDiscountCodePageQueryVariables>;
export const DiscountCodesPageDocument = gql`
    query DiscountCodesPage($states: [DiscountCodeState!]) {
  me {
    id
    role
    email
  }
  discountCodes(states: $states) {
    id
    name
    code
    discountVariant
    deliveryOptions
    amount
    state
    maximumDiscountAmount
    minimumAmountToApply
    validFrom
    validUntil
    platformFundedPercentage
    totalUsageLimit
    remains
  }
}
    `;

/**
 * __useDiscountCodesPageQuery__
 *
 * To run a query within a React component, call `useDiscountCodesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountCodesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountCodesPageQuery({
 *   variables: {
 *      states: // value for 'states'
 *   },
 * });
 */
export function useDiscountCodesPageQuery(baseOptions?: Apollo.QueryHookOptions<DiscountCodesPageQuery, DiscountCodesPageQueryVariables>) {
        return Apollo.useQuery<DiscountCodesPageQuery, DiscountCodesPageQueryVariables>(DiscountCodesPageDocument, baseOptions);
      }
export function useDiscountCodesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscountCodesPageQuery, DiscountCodesPageQueryVariables>) {
          return Apollo.useLazyQuery<DiscountCodesPageQuery, DiscountCodesPageQueryVariables>(DiscountCodesPageDocument, baseOptions);
        }
export type DiscountCodesPageQueryHookResult = ReturnType<typeof useDiscountCodesPageQuery>;
export type DiscountCodesPageLazyQueryHookResult = ReturnType<typeof useDiscountCodesPageLazyQuery>;
export type DiscountCodesPageQueryResult = Apollo.QueryResult<DiscountCodesPageQuery, DiscountCodesPageQueryVariables>;
export const MixinPromoUpdatePageDocument = gql`
    query MixinPromoUpdatePage($id: ID!) {
  me {
    id
    role
    email
  }
  mixinPromo(id: $id) {
    id
    outletIds
    storesCount
    amount
    category
    minSpend
    capped
  }
}
    `;

/**
 * __useMixinPromoUpdatePageQuery__
 *
 * To run a query within a React component, call `useMixinPromoUpdatePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMixinPromoUpdatePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMixinPromoUpdatePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMixinPromoUpdatePageQuery(baseOptions: Apollo.QueryHookOptions<MixinPromoUpdatePageQuery, MixinPromoUpdatePageQueryVariables>) {
        return Apollo.useQuery<MixinPromoUpdatePageQuery, MixinPromoUpdatePageQueryVariables>(MixinPromoUpdatePageDocument, baseOptions);
      }
export function useMixinPromoUpdatePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MixinPromoUpdatePageQuery, MixinPromoUpdatePageQueryVariables>) {
          return Apollo.useLazyQuery<MixinPromoUpdatePageQuery, MixinPromoUpdatePageQueryVariables>(MixinPromoUpdatePageDocument, baseOptions);
        }
export type MixinPromoUpdatePageQueryHookResult = ReturnType<typeof useMixinPromoUpdatePageQuery>;
export type MixinPromoUpdatePageLazyQueryHookResult = ReturnType<typeof useMixinPromoUpdatePageLazyQuery>;
export type MixinPromoUpdatePageQueryResult = Apollo.QueryResult<MixinPromoUpdatePageQuery, MixinPromoUpdatePageQueryVariables>;
export const MixinPromoPageDocument = gql`
    query MixinPromoPage {
  me {
    id
    role
    email
  }
  mixinPromos {
    id
    outletNames
    storesCount
    amount
    category
    minSpend
    capped
  }
}
    `;

/**
 * __useMixinPromoPageQuery__
 *
 * To run a query within a React component, call `useMixinPromoPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMixinPromoPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMixinPromoPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useMixinPromoPageQuery(baseOptions?: Apollo.QueryHookOptions<MixinPromoPageQuery, MixinPromoPageQueryVariables>) {
        return Apollo.useQuery<MixinPromoPageQuery, MixinPromoPageQueryVariables>(MixinPromoPageDocument, baseOptions);
      }
export function useMixinPromoPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MixinPromoPageQuery, MixinPromoPageQueryVariables>) {
          return Apollo.useLazyQuery<MixinPromoPageQuery, MixinPromoPageQueryVariables>(MixinPromoPageDocument, baseOptions);
        }
export type MixinPromoPageQueryHookResult = ReturnType<typeof useMixinPromoPageQuery>;
export type MixinPromoPageLazyQueryHookResult = ReturnType<typeof useMixinPromoPageLazyQuery>;
export type MixinPromoPageQueryResult = Apollo.QueryResult<MixinPromoPageQuery, MixinPromoPageQueryVariables>;
export const OutletPageDocument = gql`
    query OutletPage($id: ID!) {
  me {
    id
    role
    email
  }
  outlet(id: $id) {
    id
    name
    restaurants {
      id
      name
      number
      restaurantUsers {
        id
        email
        role
        phoneNumber
        createdAt
      }
    }
  }
}
    `;

/**
 * __useOutletPageQuery__
 *
 * To run a query within a React component, call `useOutletPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOutletPageQuery(baseOptions: Apollo.QueryHookOptions<OutletPageQuery, OutletPageQueryVariables>) {
        return Apollo.useQuery<OutletPageQuery, OutletPageQueryVariables>(OutletPageDocument, baseOptions);
      }
export function useOutletPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletPageQuery, OutletPageQueryVariables>) {
          return Apollo.useLazyQuery<OutletPageQuery, OutletPageQueryVariables>(OutletPageDocument, baseOptions);
        }
export type OutletPageQueryHookResult = ReturnType<typeof useOutletPageQuery>;
export type OutletPageLazyQueryHookResult = ReturnType<typeof useOutletPageLazyQuery>;
export type OutletPageQueryResult = Apollo.QueryResult<OutletPageQuery, OutletPageQueryVariables>;
export const UpdateRestaurantPageDocument = gql`
    query UpdateRestaurantPage($id: ID!) {
  me {
    id
    role
    email
  }
  outlets {
    id
    name
  }
  restaurant(id: $id) {
    id
    name
    number
    taxPercentage
    counterFeePercentage
    techFeePercentage
    revenueSharePercentage
    revenueSharePercentageNew
    revenueSharePercentageNewEffectiveDate
    cabinetSlots {
      id
      number
    }
  }
}
    `;

/**
 * __useUpdateRestaurantPageQuery__
 *
 * To run a query within a React component, call `useUpdateRestaurantPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestaurantPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateRestaurantPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateRestaurantPageQuery(baseOptions: Apollo.QueryHookOptions<UpdateRestaurantPageQuery, UpdateRestaurantPageQueryVariables>) {
        return Apollo.useQuery<UpdateRestaurantPageQuery, UpdateRestaurantPageQueryVariables>(UpdateRestaurantPageDocument, baseOptions);
      }
export function useUpdateRestaurantPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateRestaurantPageQuery, UpdateRestaurantPageQueryVariables>) {
          return Apollo.useLazyQuery<UpdateRestaurantPageQuery, UpdateRestaurantPageQueryVariables>(UpdateRestaurantPageDocument, baseOptions);
        }
export type UpdateRestaurantPageQueryHookResult = ReturnType<typeof useUpdateRestaurantPageQuery>;
export type UpdateRestaurantPageLazyQueryHookResult = ReturnType<typeof useUpdateRestaurantPageLazyQuery>;
export type UpdateRestaurantPageQueryResult = Apollo.QueryResult<UpdateRestaurantPageQuery, UpdateRestaurantPageQueryVariables>;
export const UpdateRestaurantUserPageDocument = gql`
    query UpdateRestaurantUserPage($id: ID!, $restaurantUserId: ID!) {
  me {
    id
    role
    email
  }
  restaurant(id: $id) {
    id
    name
    stores {
      id
      name
    }
    restaurantUser(id: $restaurantUserId) {
      id
      email
      countryCode
      nationalNumber
      isCashier
      isBanned
      role
      stores {
        id
      }
    }
  }
}
    `;

/**
 * __useUpdateRestaurantUserPageQuery__
 *
 * To run a query within a React component, call `useUpdateRestaurantUserPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestaurantUserPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateRestaurantUserPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      restaurantUserId: // value for 'restaurantUserId'
 *   },
 * });
 */
export function useUpdateRestaurantUserPageQuery(baseOptions: Apollo.QueryHookOptions<UpdateRestaurantUserPageQuery, UpdateRestaurantUserPageQueryVariables>) {
        return Apollo.useQuery<UpdateRestaurantUserPageQuery, UpdateRestaurantUserPageQueryVariables>(UpdateRestaurantUserPageDocument, baseOptions);
      }
export function useUpdateRestaurantUserPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateRestaurantUserPageQuery, UpdateRestaurantUserPageQueryVariables>) {
          return Apollo.useLazyQuery<UpdateRestaurantUserPageQuery, UpdateRestaurantUserPageQueryVariables>(UpdateRestaurantUserPageDocument, baseOptions);
        }
export type UpdateRestaurantUserPageQueryHookResult = ReturnType<typeof useUpdateRestaurantUserPageQuery>;
export type UpdateRestaurantUserPageLazyQueryHookResult = ReturnType<typeof useUpdateRestaurantUserPageLazyQuery>;
export type UpdateRestaurantUserPageQueryResult = Apollo.QueryResult<UpdateRestaurantUserPageQuery, UpdateRestaurantUserPageQueryVariables>;
export const CreateRestaurantUserPageDocument = gql`
    query CreateRestaurantUserPage($id: ID!) {
  me {
    id
    role
    email
  }
  restaurant(id: $id) {
    id
    name
    stores {
      id
      name
    }
  }
}
    `;

/**
 * __useCreateRestaurantUserPageQuery__
 *
 * To run a query within a React component, call `useCreateRestaurantUserPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateRestaurantUserPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateRestaurantUserPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateRestaurantUserPageQuery(baseOptions: Apollo.QueryHookOptions<CreateRestaurantUserPageQuery, CreateRestaurantUserPageQueryVariables>) {
        return Apollo.useQuery<CreateRestaurantUserPageQuery, CreateRestaurantUserPageQueryVariables>(CreateRestaurantUserPageDocument, baseOptions);
      }
export function useCreateRestaurantUserPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateRestaurantUserPageQuery, CreateRestaurantUserPageQueryVariables>) {
          return Apollo.useLazyQuery<CreateRestaurantUserPageQuery, CreateRestaurantUserPageQueryVariables>(CreateRestaurantUserPageDocument, baseOptions);
        }
export type CreateRestaurantUserPageQueryHookResult = ReturnType<typeof useCreateRestaurantUserPageQuery>;
export type CreateRestaurantUserPageLazyQueryHookResult = ReturnType<typeof useCreateRestaurantUserPageLazyQuery>;
export type CreateRestaurantUserPageQueryResult = Apollo.QueryResult<CreateRestaurantUserPageQuery, CreateRestaurantUserPageQueryVariables>;
export const OutletUpdatePageDocument = gql`
    query OutletUpdatePage($id: ID!) {
  me {
    id
    role
    email
  }
  outlet(id: $id) {
    id
    name
    address
    open
    close
    image
    slides
    slidesUrls
    hide
    cabinetSlots {
      id
      number
    }
  }
}
    `;

/**
 * __useOutletUpdatePageQuery__
 *
 * To run a query within a React component, call `useOutletUpdatePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletUpdatePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletUpdatePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOutletUpdatePageQuery(baseOptions: Apollo.QueryHookOptions<OutletUpdatePageQuery, OutletUpdatePageQueryVariables>) {
        return Apollo.useQuery<OutletUpdatePageQuery, OutletUpdatePageQueryVariables>(OutletUpdatePageDocument, baseOptions);
      }
export function useOutletUpdatePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletUpdatePageQuery, OutletUpdatePageQueryVariables>) {
          return Apollo.useLazyQuery<OutletUpdatePageQuery, OutletUpdatePageQueryVariables>(OutletUpdatePageDocument, baseOptions);
        }
export type OutletUpdatePageQueryHookResult = ReturnType<typeof useOutletUpdatePageQuery>;
export type OutletUpdatePageLazyQueryHookResult = ReturnType<typeof useOutletUpdatePageLazyQuery>;
export type OutletUpdatePageQueryResult = Apollo.QueryResult<OutletUpdatePageQuery, OutletUpdatePageQueryVariables>;
export const OutletsPageDocument = gql`
    query OutletsPage {
  me {
    id
    role
    email
  }
  outlets {
    id
    name
    image
  }
}
    `;

/**
 * __useOutletsPageQuery__
 *
 * To run a query within a React component, call `useOutletsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useOutletsPageQuery(baseOptions?: Apollo.QueryHookOptions<OutletsPageQuery, OutletsPageQueryVariables>) {
        return Apollo.useQuery<OutletsPageQuery, OutletsPageQueryVariables>(OutletsPageDocument, baseOptions);
      }
export function useOutletsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletsPageQuery, OutletsPageQueryVariables>) {
          return Apollo.useLazyQuery<OutletsPageQuery, OutletsPageQueryVariables>(OutletsPageDocument, baseOptions);
        }
export type OutletsPageQueryHookResult = ReturnType<typeof useOutletsPageQuery>;
export type OutletsPageLazyQueryHookResult = ReturnType<typeof useOutletsPageLazyQuery>;
export type OutletsPageQueryResult = Apollo.QueryResult<OutletsPageQuery, OutletsPageQueryVariables>;
export const UpdateRestaurantAdminPageDocument = gql`
    query UpdateRestaurantAdminPage($restaurantAdminId: ID!) {
  me {
    id
    role
    email
  }
  outlets {
    id
    name
    restaurants {
      id
      stores {
        id
        name
      }
    }
  }
  restaurantAdmin(id: $restaurantAdminId) {
    id
    email
    countryCode
    nationalNumber
    isCashier
    stores {
      id
    }
  }
}
    `;

/**
 * __useUpdateRestaurantAdminPageQuery__
 *
 * To run a query within a React component, call `useUpdateRestaurantAdminPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestaurantAdminPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateRestaurantAdminPageQuery({
 *   variables: {
 *      restaurantAdminId: // value for 'restaurantAdminId'
 *   },
 * });
 */
export function useUpdateRestaurantAdminPageQuery(baseOptions: Apollo.QueryHookOptions<UpdateRestaurantAdminPageQuery, UpdateRestaurantAdminPageQueryVariables>) {
        return Apollo.useQuery<UpdateRestaurantAdminPageQuery, UpdateRestaurantAdminPageQueryVariables>(UpdateRestaurantAdminPageDocument, baseOptions);
      }
export function useUpdateRestaurantAdminPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateRestaurantAdminPageQuery, UpdateRestaurantAdminPageQueryVariables>) {
          return Apollo.useLazyQuery<UpdateRestaurantAdminPageQuery, UpdateRestaurantAdminPageQueryVariables>(UpdateRestaurantAdminPageDocument, baseOptions);
        }
export type UpdateRestaurantAdminPageQueryHookResult = ReturnType<typeof useUpdateRestaurantAdminPageQuery>;
export type UpdateRestaurantAdminPageLazyQueryHookResult = ReturnType<typeof useUpdateRestaurantAdminPageLazyQuery>;
export type UpdateRestaurantAdminPageQueryResult = Apollo.QueryResult<UpdateRestaurantAdminPageQuery, UpdateRestaurantAdminPageQueryVariables>;
export const CreateRestaurantAdminPageDocument = gql`
    query CreateRestaurantAdminPage {
  me {
    id
    role
    email
  }
  outlets {
    id
    name
    restaurants {
      id
      stores {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCreateRestaurantAdminPageQuery__
 *
 * To run a query within a React component, call `useCreateRestaurantAdminPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateRestaurantAdminPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateRestaurantAdminPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateRestaurantAdminPageQuery(baseOptions?: Apollo.QueryHookOptions<CreateRestaurantAdminPageQuery, CreateRestaurantAdminPageQueryVariables>) {
        return Apollo.useQuery<CreateRestaurantAdminPageQuery, CreateRestaurantAdminPageQueryVariables>(CreateRestaurantAdminPageDocument, baseOptions);
      }
export function useCreateRestaurantAdminPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateRestaurantAdminPageQuery, CreateRestaurantAdminPageQueryVariables>) {
          return Apollo.useLazyQuery<CreateRestaurantAdminPageQuery, CreateRestaurantAdminPageQueryVariables>(CreateRestaurantAdminPageDocument, baseOptions);
        }
export type CreateRestaurantAdminPageQueryHookResult = ReturnType<typeof useCreateRestaurantAdminPageQuery>;
export type CreateRestaurantAdminPageLazyQueryHookResult = ReturnType<typeof useCreateRestaurantAdminPageLazyQuery>;
export type CreateRestaurantAdminPageQueryResult = Apollo.QueryResult<CreateRestaurantAdminPageQuery, CreateRestaurantAdminPageQueryVariables>;
export const RestaurantAdminsPageDocument = gql`
    query RestaurantAdminsPage {
  me {
    id
    role
    email
  }
  restaurantAdmins {
    id
    email
    role
    phoneNumber
    createdAt
  }
}
    `;

/**
 * __useRestaurantAdminsPageQuery__
 *
 * To run a query within a React component, call `useRestaurantAdminsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantAdminsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantAdminsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useRestaurantAdminsPageQuery(baseOptions?: Apollo.QueryHookOptions<RestaurantAdminsPageQuery, RestaurantAdminsPageQueryVariables>) {
        return Apollo.useQuery<RestaurantAdminsPageQuery, RestaurantAdminsPageQueryVariables>(RestaurantAdminsPageDocument, baseOptions);
      }
export function useRestaurantAdminsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RestaurantAdminsPageQuery, RestaurantAdminsPageQueryVariables>) {
          return Apollo.useLazyQuery<RestaurantAdminsPageQuery, RestaurantAdminsPageQueryVariables>(RestaurantAdminsPageDocument, baseOptions);
        }
export type RestaurantAdminsPageQueryHookResult = ReturnType<typeof useRestaurantAdminsPageQuery>;
export type RestaurantAdminsPageLazyQueryHookResult = ReturnType<typeof useRestaurantAdminsPageLazyQuery>;
export type RestaurantAdminsPageQueryResult = Apollo.QueryResult<RestaurantAdminsPageQuery, RestaurantAdminsPageQueryVariables>;
export const StoreListPageDocument = gql`
    query StoreListPage {
  me {
    id
    role
    email
  }
}
    `;

/**
 * __useStoreListPageQuery__
 *
 * To run a query within a React component, call `useStoreListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreListPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useStoreListPageQuery(baseOptions?: Apollo.QueryHookOptions<StoreListPageQuery, StoreListPageQueryVariables>) {
        return Apollo.useQuery<StoreListPageQuery, StoreListPageQueryVariables>(StoreListPageDocument, baseOptions);
      }
export function useStoreListPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoreListPageQuery, StoreListPageQueryVariables>) {
          return Apollo.useLazyQuery<StoreListPageQuery, StoreListPageQueryVariables>(StoreListPageDocument, baseOptions);
        }
export type StoreListPageQueryHookResult = ReturnType<typeof useStoreListPageQuery>;
export type StoreListPageLazyQueryHookResult = ReturnType<typeof useStoreListPageLazyQuery>;
export type StoreListPageQueryResult = Apollo.QueryResult<StoreListPageQuery, StoreListPageQueryVariables>;
export const UploadImagePageDocument = gql`
    query UploadImagePage {
  me {
    id
    role
    email
  }
  popUpImage {
    id
    image
  }
  outlets {
    id
    name
    popUpImage
  }
}
    `;

/**
 * __useUploadImagePageQuery__
 *
 * To run a query within a React component, call `useUploadImagePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadImagePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadImagePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useUploadImagePageQuery(baseOptions?: Apollo.QueryHookOptions<UploadImagePageQuery, UploadImagePageQueryVariables>) {
        return Apollo.useQuery<UploadImagePageQuery, UploadImagePageQueryVariables>(UploadImagePageDocument, baseOptions);
      }
export function useUploadImagePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UploadImagePageQuery, UploadImagePageQueryVariables>) {
          return Apollo.useLazyQuery<UploadImagePageQuery, UploadImagePageQueryVariables>(UploadImagePageDocument, baseOptions);
        }
export type UploadImagePageQueryHookResult = ReturnType<typeof useUploadImagePageQuery>;
export type UploadImagePageLazyQueryHookResult = ReturnType<typeof useUploadImagePageLazyQuery>;
export type UploadImagePageQueryResult = Apollo.QueryResult<UploadImagePageQuery, UploadImagePageQueryVariables>;
export const ViewingBookingsPageDocument = gql`
    query ViewingBookingsPage {
  me {
    id
    email
    role
  }
  viewingBookings {
    id
    firstName
    lastName
    phoneNumber
    email
    brand
    location
    date
    timeSlot
  }
}
    `;

/**
 * __useViewingBookingsPageQuery__
 *
 * To run a query within a React component, call `useViewingBookingsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewingBookingsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewingBookingsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewingBookingsPageQuery(baseOptions?: Apollo.QueryHookOptions<ViewingBookingsPageQuery, ViewingBookingsPageQueryVariables>) {
        return Apollo.useQuery<ViewingBookingsPageQuery, ViewingBookingsPageQueryVariables>(ViewingBookingsPageDocument, baseOptions);
      }
export function useViewingBookingsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewingBookingsPageQuery, ViewingBookingsPageQueryVariables>) {
          return Apollo.useLazyQuery<ViewingBookingsPageQuery, ViewingBookingsPageQueryVariables>(ViewingBookingsPageDocument, baseOptions);
        }
export type ViewingBookingsPageQueryHookResult = ReturnType<typeof useViewingBookingsPageQuery>;
export type ViewingBookingsPageLazyQueryHookResult = ReturnType<typeof useViewingBookingsPageLazyQuery>;
export type ViewingBookingsPageQueryResult = Apollo.QueryResult<ViewingBookingsPageQuery, ViewingBookingsPageQueryVariables>;
export const AppMetadataDocument = gql`
    query AppMetadata {
  app {
    id
    operatingCountries {
      id
      country
      code
      currency {
        id
        symbol
        code
      }
    }
  }
}
    `;

/**
 * __useAppMetadataQuery__
 *
 * To run a query within a React component, call `useAppMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppMetadataQuery(baseOptions?: Apollo.QueryHookOptions<AppMetadataQuery, AppMetadataQueryVariables>) {
        return Apollo.useQuery<AppMetadataQuery, AppMetadataQueryVariables>(AppMetadataDocument, baseOptions);
      }
export function useAppMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppMetadataQuery, AppMetadataQueryVariables>) {
          return Apollo.useLazyQuery<AppMetadataQuery, AppMetadataQueryVariables>(AppMetadataDocument, baseOptions);
        }
export type AppMetadataQueryHookResult = ReturnType<typeof useAppMetadataQuery>;
export type AppMetadataLazyQueryHookResult = ReturnType<typeof useAppMetadataLazyQuery>;
export type AppMetadataQueryResult = Apollo.QueryResult<AppMetadataQuery, AppMetadataQueryVariables>;
export const ConsumersDocument = gql`
    query Consumers($startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!, $search: String, $page: Int!) {
  allConsumers(
    startDate: $startDate
    endDate: $endDate
    search: $search
    page: $page
  ) {
    id
    total
    pageLimit
    consumers {
      id
      name
      email
      phoneNumber
      newsletterSubscribed
      ordersCount
      createdAt
    }
  }
}
    `;

/**
 * __useConsumersQuery__
 *
 * To run a query within a React component, call `useConsumersQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumersQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      search: // value for 'search'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useConsumersQuery(baseOptions: Apollo.QueryHookOptions<ConsumersQuery, ConsumersQueryVariables>) {
        return Apollo.useQuery<ConsumersQuery, ConsumersQueryVariables>(ConsumersDocument, baseOptions);
      }
export function useConsumersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsumersQuery, ConsumersQueryVariables>) {
          return Apollo.useLazyQuery<ConsumersQuery, ConsumersQueryVariables>(ConsumersDocument, baseOptions);
        }
export type ConsumersQueryHookResult = ReturnType<typeof useConsumersQuery>;
export type ConsumersLazyQueryHookResult = ReturnType<typeof useConsumersLazyQuery>;
export type ConsumersQueryResult = Apollo.QueryResult<ConsumersQuery, ConsumersQueryVariables>;
export const ConsumersLastOrderDocument = gql`
    query ConsumersLastOrder($startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!, $search: String, $page: Int!) {
  allConsumersBasedOnLastOrder(
    startDate: $startDate
    endDate: $endDate
    search: $search
    page: $page
  ) {
    id
    total
    pageLimit
    consumers {
      id
      name
      email
      phoneNumber
      ordersCountLastOrder
      lastOrderAt
    }
  }
}
    `;

/**
 * __useConsumersLastOrderQuery__
 *
 * To run a query within a React component, call `useConsumersLastOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumersLastOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumersLastOrderQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      search: // value for 'search'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useConsumersLastOrderQuery(baseOptions: Apollo.QueryHookOptions<ConsumersLastOrderQuery, ConsumersLastOrderQueryVariables>) {
        return Apollo.useQuery<ConsumersLastOrderQuery, ConsumersLastOrderQueryVariables>(ConsumersLastOrderDocument, baseOptions);
      }
export function useConsumersLastOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsumersLastOrderQuery, ConsumersLastOrderQueryVariables>) {
          return Apollo.useLazyQuery<ConsumersLastOrderQuery, ConsumersLastOrderQueryVariables>(ConsumersLastOrderDocument, baseOptions);
        }
export type ConsumersLastOrderQueryHookResult = ReturnType<typeof useConsumersLastOrderQuery>;
export type ConsumersLastOrderLazyQueryHookResult = ReturnType<typeof useConsumersLastOrderLazyQuery>;
export type ConsumersLastOrderQueryResult = Apollo.QueryResult<ConsumersLastOrderQuery, ConsumersLastOrderQueryVariables>;
export const DiscountCodeDocument = gql`
    query DiscountCode($id: ID!) {
  discountCode(id: $id) {
    id
    name
    code
    discountVariant
    deliveryOptions
    paymentMethods
    shouldAutoIncludeNewStore
    amount
    maximumDiscountAmount
    minimumAmountToApply
    dailyLimit
    totalUsageLimit
    validFrom
    validUntil
    platformFundedPercentage
    storeDiscountCodes {
      id
      storeId
      storeName
      totalUsageLimit
    }
  }
}
    `;

/**
 * __useDiscountCodeQuery__
 *
 * To run a query within a React component, call `useDiscountCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountCodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDiscountCodeQuery(baseOptions: Apollo.QueryHookOptions<DiscountCodeQuery, DiscountCodeQueryVariables>) {
        return Apollo.useQuery<DiscountCodeQuery, DiscountCodeQueryVariables>(DiscountCodeDocument, baseOptions);
      }
export function useDiscountCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscountCodeQuery, DiscountCodeQueryVariables>) {
          return Apollo.useLazyQuery<DiscountCodeQuery, DiscountCodeQueryVariables>(DiscountCodeDocument, baseOptions);
        }
export type DiscountCodeQueryHookResult = ReturnType<typeof useDiscountCodeQuery>;
export type DiscountCodeLazyQueryHookResult = ReturnType<typeof useDiscountCodeLazyQuery>;
export type DiscountCodeQueryResult = Apollo.QueryResult<DiscountCodeQuery, DiscountCodeQueryVariables>;
export const ExportMerchantSalesCsvDocument = gql`
    query ExportMerchantSalesCsv($storeIds: [ID!], $paymentMethods: [PaymentMethod!], $integrations: [Integration!], $states: [OrderState!], $startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
  merchantSalesCsv(
    storeIds: $storeIds
    paymentMethods: $paymentMethods
    integrations: $integrations
    states: $states
    startDate: $startDate
    endDate: $endDate
  )
}
    `;

/**
 * __useExportMerchantSalesCsvQuery__
 *
 * To run a query within a React component, call `useExportMerchantSalesCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportMerchantSalesCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportMerchantSalesCsvQuery({
 *   variables: {
 *      storeIds: // value for 'storeIds'
 *      paymentMethods: // value for 'paymentMethods'
 *      integrations: // value for 'integrations'
 *      states: // value for 'states'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useExportMerchantSalesCsvQuery(baseOptions: Apollo.QueryHookOptions<ExportMerchantSalesCsvQuery, ExportMerchantSalesCsvQueryVariables>) {
        return Apollo.useQuery<ExportMerchantSalesCsvQuery, ExportMerchantSalesCsvQueryVariables>(ExportMerchantSalesCsvDocument, baseOptions);
      }
export function useExportMerchantSalesCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportMerchantSalesCsvQuery, ExportMerchantSalesCsvQueryVariables>) {
          return Apollo.useLazyQuery<ExportMerchantSalesCsvQuery, ExportMerchantSalesCsvQueryVariables>(ExportMerchantSalesCsvDocument, baseOptions);
        }
export type ExportMerchantSalesCsvQueryHookResult = ReturnType<typeof useExportMerchantSalesCsvQuery>;
export type ExportMerchantSalesCsvLazyQueryHookResult = ReturnType<typeof useExportMerchantSalesCsvLazyQuery>;
export type ExportMerchantSalesCsvQueryResult = Apollo.QueryResult<ExportMerchantSalesCsvQuery, ExportMerchantSalesCsvQueryVariables>;
export const ExportConsumerCsvDocument = gql`
    query ExportConsumerCsv($startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
  consumerCsv(startDate: $startDate, endDate: $endDate)
}
    `;

/**
 * __useExportConsumerCsvQuery__
 *
 * To run a query within a React component, call `useExportConsumerCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportConsumerCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportConsumerCsvQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useExportConsumerCsvQuery(baseOptions: Apollo.QueryHookOptions<ExportConsumerCsvQuery, ExportConsumerCsvQueryVariables>) {
        return Apollo.useQuery<ExportConsumerCsvQuery, ExportConsumerCsvQueryVariables>(ExportConsumerCsvDocument, baseOptions);
      }
export function useExportConsumerCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportConsumerCsvQuery, ExportConsumerCsvQueryVariables>) {
          return Apollo.useLazyQuery<ExportConsumerCsvQuery, ExportConsumerCsvQueryVariables>(ExportConsumerCsvDocument, baseOptions);
        }
export type ExportConsumerCsvQueryHookResult = ReturnType<typeof useExportConsumerCsvQuery>;
export type ExportConsumerCsvLazyQueryHookResult = ReturnType<typeof useExportConsumerCsvLazyQuery>;
export type ExportConsumerCsvQueryResult = Apollo.QueryResult<ExportConsumerCsvQuery, ExportConsumerCsvQueryVariables>;
export const ExportConsumerLastOrderCsvDocument = gql`
    query ExportConsumerLastOrderCsv($startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
  consumerLastOrderCsv(startDate: $startDate, endDate: $endDate)
}
    `;

/**
 * __useExportConsumerLastOrderCsvQuery__
 *
 * To run a query within a React component, call `useExportConsumerLastOrderCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportConsumerLastOrderCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportConsumerLastOrderCsvQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useExportConsumerLastOrderCsvQuery(baseOptions: Apollo.QueryHookOptions<ExportConsumerLastOrderCsvQuery, ExportConsumerLastOrderCsvQueryVariables>) {
        return Apollo.useQuery<ExportConsumerLastOrderCsvQuery, ExportConsumerLastOrderCsvQueryVariables>(ExportConsumerLastOrderCsvDocument, baseOptions);
      }
export function useExportConsumerLastOrderCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportConsumerLastOrderCsvQuery, ExportConsumerLastOrderCsvQueryVariables>) {
          return Apollo.useLazyQuery<ExportConsumerLastOrderCsvQuery, ExportConsumerLastOrderCsvQueryVariables>(ExportConsumerLastOrderCsvDocument, baseOptions);
        }
export type ExportConsumerLastOrderCsvQueryHookResult = ReturnType<typeof useExportConsumerLastOrderCsvQuery>;
export type ExportConsumerLastOrderCsvLazyQueryHookResult = ReturnType<typeof useExportConsumerLastOrderCsvLazyQuery>;
export type ExportConsumerLastOrderCsvQueryResult = Apollo.QueryResult<ExportConsumerLastOrderCsvQuery, ExportConsumerLastOrderCsvQueryVariables>;
export const GrowthDocument = gql`
    query Growth {
  totalUsersCount
  totalOrdersCount
}
    `;

/**
 * __useGrowthQuery__
 *
 * To run a query within a React component, call `useGrowthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrowthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrowthQuery({
 *   variables: {
 *   },
 * });
 */
export function useGrowthQuery(baseOptions?: Apollo.QueryHookOptions<GrowthQuery, GrowthQueryVariables>) {
        return Apollo.useQuery<GrowthQuery, GrowthQueryVariables>(GrowthDocument, baseOptions);
      }
export function useGrowthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GrowthQuery, GrowthQueryVariables>) {
          return Apollo.useLazyQuery<GrowthQuery, GrowthQueryVariables>(GrowthDocument, baseOptions);
        }
export type GrowthQueryHookResult = ReturnType<typeof useGrowthQuery>;
export type GrowthLazyQueryHookResult = ReturnType<typeof useGrowthLazyQuery>;
export type GrowthQueryResult = Apollo.QueryResult<GrowthQuery, GrowthQueryVariables>;
export const KitchensDocument = gql`
    query Kitchens($outletIds: [ID!]!) {
  restaurants(outletIds: $outletIds) {
    id
    name
    number
  }
}
    `;

/**
 * __useKitchensQuery__
 *
 * To run a query within a React component, call `useKitchensQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchensQuery({
 *   variables: {
 *      outletIds: // value for 'outletIds'
 *   },
 * });
 */
export function useKitchensQuery(baseOptions: Apollo.QueryHookOptions<KitchensQuery, KitchensQueryVariables>) {
        return Apollo.useQuery<KitchensQuery, KitchensQueryVariables>(KitchensDocument, baseOptions);
      }
export function useKitchensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchensQuery, KitchensQueryVariables>) {
          return Apollo.useLazyQuery<KitchensQuery, KitchensQueryVariables>(KitchensDocument, baseOptions);
        }
export type KitchensQueryHookResult = ReturnType<typeof useKitchensQuery>;
export type KitchensLazyQueryHookResult = ReturnType<typeof useKitchensLazyQuery>;
export type KitchensQueryResult = Apollo.QueryResult<KitchensQuery, KitchensQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    email
    role
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MixOrdersDocument = gql`
    query MixOrders($states: [OrderState!]!, $storeIds: [ID!]!, $deliveryOptions: [DeliveryOption!]!) {
  mixOrders(
    states: $states
    storeIds: $storeIds
    deliveryOptions: $deliveryOptions
  ) {
    id
    storeOrders {
      id
      state
      orderCode
      integration
      cabinetSlotNumber
      storeName
      updatedAt
    }
  }
}
    `;

/**
 * __useMixOrdersQuery__
 *
 * To run a query within a React component, call `useMixOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMixOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMixOrdersQuery({
 *   variables: {
 *      states: // value for 'states'
 *      storeIds: // value for 'storeIds'
 *      deliveryOptions: // value for 'deliveryOptions'
 *   },
 * });
 */
export function useMixOrdersQuery(baseOptions: Apollo.QueryHookOptions<MixOrdersQuery, MixOrdersQueryVariables>) {
        return Apollo.useQuery<MixOrdersQuery, MixOrdersQueryVariables>(MixOrdersDocument, baseOptions);
      }
export function useMixOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MixOrdersQuery, MixOrdersQueryVariables>) {
          return Apollo.useLazyQuery<MixOrdersQuery, MixOrdersQueryVariables>(MixOrdersDocument, baseOptions);
        }
export type MixOrdersQueryHookResult = ReturnType<typeof useMixOrdersQuery>;
export type MixOrdersLazyQueryHookResult = ReturnType<typeof useMixOrdersLazyQuery>;
export type MixOrdersQueryResult = Apollo.QueryResult<MixOrdersQuery, MixOrdersQueryVariables>;
export const UncollectedOrdersDocument = gql`
    query UncollectedOrders($outletIds: [ID!]!) {
  uncollectedOrders(outletIds: $outletIds) {
    id
    orderCode
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useUncollectedOrdersQuery__
 *
 * To run a query within a React component, call `useUncollectedOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUncollectedOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUncollectedOrdersQuery({
 *   variables: {
 *      outletIds: // value for 'outletIds'
 *   },
 * });
 */
export function useUncollectedOrdersQuery(baseOptions: Apollo.QueryHookOptions<UncollectedOrdersQuery, UncollectedOrdersQueryVariables>) {
        return Apollo.useQuery<UncollectedOrdersQuery, UncollectedOrdersQueryVariables>(UncollectedOrdersDocument, baseOptions);
      }
export function useUncollectedOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UncollectedOrdersQuery, UncollectedOrdersQueryVariables>) {
          return Apollo.useLazyQuery<UncollectedOrdersQuery, UncollectedOrdersQueryVariables>(UncollectedOrdersDocument, baseOptions);
        }
export type UncollectedOrdersQueryHookResult = ReturnType<typeof useUncollectedOrdersQuery>;
export type UncollectedOrdersLazyQueryHookResult = ReturnType<typeof useUncollectedOrdersLazyQuery>;
export type UncollectedOrdersQueryResult = Apollo.QueryResult<UncollectedOrdersQuery, UncollectedOrdersQueryVariables>;
export const OutletsForDropdownDocument = gql`
    query OutletsForDropdown {
  outlets {
    id
    name
  }
}
    `;

/**
 * __useOutletsForDropdownQuery__
 *
 * To run a query within a React component, call `useOutletsForDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutletsForDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutletsForDropdownQuery({
 *   variables: {
 *   },
 * });
 */
export function useOutletsForDropdownQuery(baseOptions?: Apollo.QueryHookOptions<OutletsForDropdownQuery, OutletsForDropdownQueryVariables>) {
        return Apollo.useQuery<OutletsForDropdownQuery, OutletsForDropdownQueryVariables>(OutletsForDropdownDocument, baseOptions);
      }
export function useOutletsForDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutletsForDropdownQuery, OutletsForDropdownQueryVariables>) {
          return Apollo.useLazyQuery<OutletsForDropdownQuery, OutletsForDropdownQueryVariables>(OutletsForDropdownDocument, baseOptions);
        }
export type OutletsForDropdownQueryHookResult = ReturnType<typeof useOutletsForDropdownQuery>;
export type OutletsForDropdownLazyQueryHookResult = ReturnType<typeof useOutletsForDropdownLazyQuery>;
export type OutletsForDropdownQueryResult = Apollo.QueryResult<OutletsForDropdownQuery, OutletsForDropdownQueryVariables>;
export const MerchantSalesDocument = gql`
    query MerchantSales($startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!, $integrations: [Integration!], $states: [OrderState!], $storeIds: [ID!], $paymentMethods: [PaymentMethod!], $page: Int!) {
  merchantSales(
    startDate: $startDate
    endDate: $endDate
    integrations: $integrations
    states: $states
    storeIds: $storeIds
    paymentMethods: $paymentMethods
    page: $page
  ) {
    id
    pageLimit
    sales {
      id
      state
      orderCode
      orderId
      integration
      state
      paymentMethod
      createdAt
      discountCode
      originalPrice
      isFirstMobileOrder
      platformFundedPromo
      merchantFundedPromo
      counterFeePercentage
      platformFeePercentage
      revenueSharePercentage
      revenue
      tax
      externalFee
      counterFee
      platformFee
      revenueShare
      netIncome
      store {
        id
        name
        country
        variant
        restaurant {
          id
          name
          number
        }
      }
    }
  }
}
    `;

/**
 * __useMerchantSalesQuery__
 *
 * To run a query within a React component, call `useMerchantSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantSalesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      integrations: // value for 'integrations'
 *      states: // value for 'states'
 *      storeIds: // value for 'storeIds'
 *      paymentMethods: // value for 'paymentMethods'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useMerchantSalesQuery(baseOptions: Apollo.QueryHookOptions<MerchantSalesQuery, MerchantSalesQueryVariables>) {
        return Apollo.useQuery<MerchantSalesQuery, MerchantSalesQueryVariables>(MerchantSalesDocument, baseOptions);
      }
export function useMerchantSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantSalesQuery, MerchantSalesQueryVariables>) {
          return Apollo.useLazyQuery<MerchantSalesQuery, MerchantSalesQueryVariables>(MerchantSalesDocument, baseOptions);
        }
export type MerchantSalesQueryHookResult = ReturnType<typeof useMerchantSalesQuery>;
export type MerchantSalesLazyQueryHookResult = ReturnType<typeof useMerchantSalesLazyQuery>;
export type MerchantSalesQueryResult = Apollo.QueryResult<MerchantSalesQuery, MerchantSalesQueryVariables>;
export const MerchantSalesTotalDocument = gql`
    query MerchantSalesTotal($startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!, $integrations: [Integration!], $states: [OrderState!], $storeIds: [ID!], $paymentMethods: [PaymentMethod!]) {
  merchantSalesTotal(
    startDate: $startDate
    endDate: $endDate
    integrations: $integrations
    states: $states
    storeIds: $storeIds
    paymentMethods: $paymentMethods
  ) {
    id
    totalCount
    totalOriginalPrice
    totalPlatformFundedPromo
    totalMerchantFundedPromo
    totalRevenue
    totalTax
    totalExternalFee
    totalCounterFee
    totalPlatformFee
    totalRevenueShare
    totalNetIncome
  }
}
    `;

/**
 * __useMerchantSalesTotalQuery__
 *
 * To run a query within a React component, call `useMerchantSalesTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantSalesTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantSalesTotalQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      integrations: // value for 'integrations'
 *      states: // value for 'states'
 *      storeIds: // value for 'storeIds'
 *      paymentMethods: // value for 'paymentMethods'
 *   },
 * });
 */
export function useMerchantSalesTotalQuery(baseOptions: Apollo.QueryHookOptions<MerchantSalesTotalQuery, MerchantSalesTotalQueryVariables>) {
        return Apollo.useQuery<MerchantSalesTotalQuery, MerchantSalesTotalQueryVariables>(MerchantSalesTotalDocument, baseOptions);
      }
export function useMerchantSalesTotalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantSalesTotalQuery, MerchantSalesTotalQueryVariables>) {
          return Apollo.useLazyQuery<MerchantSalesTotalQuery, MerchantSalesTotalQueryVariables>(MerchantSalesTotalDocument, baseOptions);
        }
export type MerchantSalesTotalQueryHookResult = ReturnType<typeof useMerchantSalesTotalQuery>;
export type MerchantSalesTotalLazyQueryHookResult = ReturnType<typeof useMerchantSalesTotalLazyQuery>;
export type MerchantSalesTotalQueryResult = Apollo.QueryResult<MerchantSalesTotalQuery, MerchantSalesTotalQueryVariables>;
export const KitchenSalesBreakdownDocument = gql`
    query KitchenSalesBreakdown($outletIds: [ID!]!, $startDate: ISO8601DateTime!, $endDate: ISO8601DateTime!) {
  kitchenSalesBreakdown(
    outletIds: $outletIds
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    totalRevenue
    totalCounterFee
    totalPlatformFee
    totalRevenueShare
    kitchenSales {
      id
      kitchenName
      kitchenNumber
      totalRevenue
      totalCounterFee
      totalPlatformFee
      totalRevenueShare
      sales {
        id
        brand
        brandName
        revenue
        counterFee
        platformFee
        revenueShare
      }
    }
  }
}
    `;

/**
 * __useKitchenSalesBreakdownQuery__
 *
 * To run a query within a React component, call `useKitchenSalesBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenSalesBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenSalesBreakdownQuery({
 *   variables: {
 *      outletIds: // value for 'outletIds'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useKitchenSalesBreakdownQuery(baseOptions: Apollo.QueryHookOptions<KitchenSalesBreakdownQuery, KitchenSalesBreakdownQueryVariables>) {
        return Apollo.useQuery<KitchenSalesBreakdownQuery, KitchenSalesBreakdownQueryVariables>(KitchenSalesBreakdownDocument, baseOptions);
      }
export function useKitchenSalesBreakdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenSalesBreakdownQuery, KitchenSalesBreakdownQueryVariables>) {
          return Apollo.useLazyQuery<KitchenSalesBreakdownQuery, KitchenSalesBreakdownQueryVariables>(KitchenSalesBreakdownDocument, baseOptions);
        }
export type KitchenSalesBreakdownQueryHookResult = ReturnType<typeof useKitchenSalesBreakdownQuery>;
export type KitchenSalesBreakdownLazyQueryHookResult = ReturnType<typeof useKitchenSalesBreakdownLazyQuery>;
export type KitchenSalesBreakdownQueryResult = Apollo.QueryResult<KitchenSalesBreakdownQuery, KitchenSalesBreakdownQueryVariables>;
export const KitchenSalesComparisonOutletDocument = gql`
    query KitchenSalesComparisonOutlet($outletIds: [ID!]!, $integrations: [Integration!], $monthCount: Int) {
  kitchenSalesComparisonOutlet(
    outletIds: $outletIds
    integrations: $integrations
    monthCount: $monthCount
  ) {
    id
    salesComparisonData {
      id
      restaurantId
      categoryName
      beforeName
      afterName
      beforeValue
      afterValue
    }
  }
}
    `;

/**
 * __useKitchenSalesComparisonOutletQuery__
 *
 * To run a query within a React component, call `useKitchenSalesComparisonOutletQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenSalesComparisonOutletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenSalesComparisonOutletQuery({
 *   variables: {
 *      outletIds: // value for 'outletIds'
 *      integrations: // value for 'integrations'
 *      monthCount: // value for 'monthCount'
 *   },
 * });
 */
export function useKitchenSalesComparisonOutletQuery(baseOptions: Apollo.QueryHookOptions<KitchenSalesComparisonOutletQuery, KitchenSalesComparisonOutletQueryVariables>) {
        return Apollo.useQuery<KitchenSalesComparisonOutletQuery, KitchenSalesComparisonOutletQueryVariables>(KitchenSalesComparisonOutletDocument, baseOptions);
      }
export function useKitchenSalesComparisonOutletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenSalesComparisonOutletQuery, KitchenSalesComparisonOutletQueryVariables>) {
          return Apollo.useLazyQuery<KitchenSalesComparisonOutletQuery, KitchenSalesComparisonOutletQueryVariables>(KitchenSalesComparisonOutletDocument, baseOptions);
        }
export type KitchenSalesComparisonOutletQueryHookResult = ReturnType<typeof useKitchenSalesComparisonOutletQuery>;
export type KitchenSalesComparisonOutletLazyQueryHookResult = ReturnType<typeof useKitchenSalesComparisonOutletLazyQuery>;
export type KitchenSalesComparisonOutletQueryResult = Apollo.QueryResult<KitchenSalesComparisonOutletQuery, KitchenSalesComparisonOutletQueryVariables>;
export const KitchenSalesComparisonRestaurantDocument = gql`
    query KitchenSalesComparisonRestaurant($restaurantId: ID!, $integrations: [Integration!], $monthCount: Int) {
  kitchenSalesComparisonRestaurant(
    restaurantId: $restaurantId
    integrations: $integrations
    monthCount: $monthCount
  ) {
    id
    salesComparisonData {
      id
      restaurantId
      categoryName
      beforeName
      afterName
      beforeValue
      afterValue
    }
  }
}
    `;

/**
 * __useKitchenSalesComparisonRestaurantQuery__
 *
 * To run a query within a React component, call `useKitchenSalesComparisonRestaurantQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenSalesComparisonRestaurantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenSalesComparisonRestaurantQuery({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      integrations: // value for 'integrations'
 *      monthCount: // value for 'monthCount'
 *   },
 * });
 */
export function useKitchenSalesComparisonRestaurantQuery(baseOptions: Apollo.QueryHookOptions<KitchenSalesComparisonRestaurantQuery, KitchenSalesComparisonRestaurantQueryVariables>) {
        return Apollo.useQuery<KitchenSalesComparisonRestaurantQuery, KitchenSalesComparisonRestaurantQueryVariables>(KitchenSalesComparisonRestaurantDocument, baseOptions);
      }
export function useKitchenSalesComparisonRestaurantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenSalesComparisonRestaurantQuery, KitchenSalesComparisonRestaurantQueryVariables>) {
          return Apollo.useLazyQuery<KitchenSalesComparisonRestaurantQuery, KitchenSalesComparisonRestaurantQueryVariables>(KitchenSalesComparisonRestaurantDocument, baseOptions);
        }
export type KitchenSalesComparisonRestaurantQueryHookResult = ReturnType<typeof useKitchenSalesComparisonRestaurantQuery>;
export type KitchenSalesComparisonRestaurantLazyQueryHookResult = ReturnType<typeof useKitchenSalesComparisonRestaurantLazyQuery>;
export type KitchenSalesComparisonRestaurantQueryResult = Apollo.QueryResult<KitchenSalesComparisonRestaurantQuery, KitchenSalesComparisonRestaurantQueryVariables>;
export const SettingsDocument = gql`
    query Settings {
  settings {
    firstOrder
    storeAutoAssignEmails
    bookingViewingEmails
    enableMailing
    operatingCountries
    consumerAnnouncement
    kitchenAppVersion
    kitchenAppDownloadUrl
    supplyBnpl
  }
}
    `;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsQuery(baseOptions?: Apollo.QueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
        return Apollo.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, baseOptions);
      }
export function useSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
          return Apollo.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, baseOptions);
        }
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>;
export type SettingsQueryResult = Apollo.QueryResult<SettingsQuery, SettingsQueryVariables>;
export const SettlementsDocument = gql`
    query Settlements($restaurantIds: [ID!]!, $dateRanges: [DateRanges!]!) {
  settlements(restaurantIds: $restaurantIds, dateRanges: $dateRanges) {
    id
    pdf
    createdAt
    restaurant {
      id
      name
      number
      outlet {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSettlementsQuery__
 *
 * To run a query within a React component, call `useSettlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettlementsQuery({
 *   variables: {
 *      restaurantIds: // value for 'restaurantIds'
 *      dateRanges: // value for 'dateRanges'
 *   },
 * });
 */
export function useSettlementsQuery(baseOptions: Apollo.QueryHookOptions<SettlementsQuery, SettlementsQueryVariables>) {
        return Apollo.useQuery<SettlementsQuery, SettlementsQueryVariables>(SettlementsDocument, baseOptions);
      }
export function useSettlementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettlementsQuery, SettlementsQueryVariables>) {
          return Apollo.useLazyQuery<SettlementsQuery, SettlementsQueryVariables>(SettlementsDocument, baseOptions);
        }
export type SettlementsQueryHookResult = ReturnType<typeof useSettlementsQuery>;
export type SettlementsLazyQueryHookResult = ReturnType<typeof useSettlementsLazyQuery>;
export type SettlementsQueryResult = Apollo.QueryResult<SettlementsQuery, SettlementsQueryVariables>;
export const SettlementsSummaryDocument = gql`
    query SettlementsSummary($dateRanges: [DateRanges!]!) {
  settlementsSummary(dateRanges: $dateRanges) {
    id
    pdf
    createdAt
  }
}
    `;

/**
 * __useSettlementsSummaryQuery__
 *
 * To run a query within a React component, call `useSettlementsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettlementsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettlementsSummaryQuery({
 *   variables: {
 *      dateRanges: // value for 'dateRanges'
 *   },
 * });
 */
export function useSettlementsSummaryQuery(baseOptions: Apollo.QueryHookOptions<SettlementsSummaryQuery, SettlementsSummaryQueryVariables>) {
        return Apollo.useQuery<SettlementsSummaryQuery, SettlementsSummaryQueryVariables>(SettlementsSummaryDocument, baseOptions);
      }
export function useSettlementsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettlementsSummaryQuery, SettlementsSummaryQueryVariables>) {
          return Apollo.useLazyQuery<SettlementsSummaryQuery, SettlementsSummaryQueryVariables>(SettlementsSummaryDocument, baseOptions);
        }
export type SettlementsSummaryQueryHookResult = ReturnType<typeof useSettlementsSummaryQuery>;
export type SettlementsSummaryLazyQueryHookResult = ReturnType<typeof useSettlementsSummaryLazyQuery>;
export type SettlementsSummaryQueryResult = Apollo.QueryResult<SettlementsSummaryQuery, SettlementsSummaryQueryVariables>;
export const SettlementsDownloadDocument = gql`
    query SettlementsDownload($ids: [ID!]!) {
  settlementsDownload(ids: $ids)
}
    `;

/**
 * __useSettlementsDownloadQuery__
 *
 * To run a query within a React component, call `useSettlementsDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettlementsDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettlementsDownloadQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSettlementsDownloadQuery(baseOptions: Apollo.QueryHookOptions<SettlementsDownloadQuery, SettlementsDownloadQueryVariables>) {
        return Apollo.useQuery<SettlementsDownloadQuery, SettlementsDownloadQueryVariables>(SettlementsDownloadDocument, baseOptions);
      }
export function useSettlementsDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettlementsDownloadQuery, SettlementsDownloadQueryVariables>) {
          return Apollo.useLazyQuery<SettlementsDownloadQuery, SettlementsDownloadQueryVariables>(SettlementsDownloadDocument, baseOptions);
        }
export type SettlementsDownloadQueryHookResult = ReturnType<typeof useSettlementsDownloadQuery>;
export type SettlementsDownloadLazyQueryHookResult = ReturnType<typeof useSettlementsDownloadLazyQuery>;
export type SettlementsDownloadQueryResult = Apollo.QueryResult<SettlementsDownloadQuery, SettlementsDownloadQueryVariables>;
export const AllStoreDocument = gql`
    query AllStore($kitchenIds: [ID!]!) {
  stores(restaurantIds: $kitchenIds) {
    id
    name
  }
}
    `;

/**
 * __useAllStoreQuery__
 *
 * To run a query within a React component, call `useAllStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllStoreQuery({
 *   variables: {
 *      kitchenIds: // value for 'kitchenIds'
 *   },
 * });
 */
export function useAllStoreQuery(baseOptions: Apollo.QueryHookOptions<AllStoreQuery, AllStoreQueryVariables>) {
        return Apollo.useQuery<AllStoreQuery, AllStoreQueryVariables>(AllStoreDocument, baseOptions);
      }
export function useAllStoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllStoreQuery, AllStoreQueryVariables>) {
          return Apollo.useLazyQuery<AllStoreQuery, AllStoreQueryVariables>(AllStoreDocument, baseOptions);
        }
export type AllStoreQueryHookResult = ReturnType<typeof useAllStoreQuery>;
export type AllStoreLazyQueryHookResult = ReturnType<typeof useAllStoreLazyQuery>;
export type AllStoreQueryResult = Apollo.QueryResult<AllStoreQuery, AllStoreQueryVariables>;
export const StoresMultiOutletsDocument = gql`
    query StoresMultiOutlets($outletIds: [ID!]) {
  storesMultiOutlets(outletIds: $outletIds) {
    id
    name
    restaurant {
      outlet {
        id
      }
    }
  }
}
    `;

/**
 * __useStoresMultiOutletsQuery__
 *
 * To run a query within a React component, call `useStoresMultiOutletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoresMultiOutletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoresMultiOutletsQuery({
 *   variables: {
 *      outletIds: // value for 'outletIds'
 *   },
 * });
 */
export function useStoresMultiOutletsQuery(baseOptions?: Apollo.QueryHookOptions<StoresMultiOutletsQuery, StoresMultiOutletsQueryVariables>) {
        return Apollo.useQuery<StoresMultiOutletsQuery, StoresMultiOutletsQueryVariables>(StoresMultiOutletsDocument, baseOptions);
      }
export function useStoresMultiOutletsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoresMultiOutletsQuery, StoresMultiOutletsQueryVariables>) {
          return Apollo.useLazyQuery<StoresMultiOutletsQuery, StoresMultiOutletsQueryVariables>(StoresMultiOutletsDocument, baseOptions);
        }
export type StoresMultiOutletsQueryHookResult = ReturnType<typeof useStoresMultiOutletsQuery>;
export type StoresMultiOutletsLazyQueryHookResult = ReturnType<typeof useStoresMultiOutletsLazyQuery>;
export type StoresMultiOutletsQueryResult = Apollo.QueryResult<StoresMultiOutletsQuery, StoresMultiOutletsQueryVariables>;
export const SuppliersDocument = gql`
    query Suppliers {
  suppliers {
    ...Suppliers
  }
}
    ${SuppliersFragmentDoc}`;

/**
 * __useSuppliersQuery__
 *
 * To run a query within a React component, call `useSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuppliersQuery(baseOptions?: Apollo.QueryHookOptions<SuppliersQuery, SuppliersQueryVariables>) {
        return Apollo.useQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, baseOptions);
      }
export function useSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuppliersQuery, SuppliersQueryVariables>) {
          return Apollo.useLazyQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, baseOptions);
        }
export type SuppliersQueryHookResult = ReturnType<typeof useSuppliersQuery>;
export type SuppliersLazyQueryHookResult = ReturnType<typeof useSuppliersLazyQuery>;
export type SuppliersQueryResult = Apollo.QueryResult<SuppliersQuery, SuppliersQueryVariables>;
export const SupplierPurchaseOrdersDocument = gql`
    query SupplierPurchaseOrders($states: [SupplierPurchaseOrderRestaurantUserState!]!) {
  supplierPurchaseOrders(states: $states) {
    ...SupplierPurchaseOrders
  }
}
    ${SupplierPurchaseOrdersFragmentDoc}`;

/**
 * __useSupplierPurchaseOrdersQuery__
 *
 * To run a query within a React component, call `useSupplierPurchaseOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierPurchaseOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierPurchaseOrdersQuery({
 *   variables: {
 *      states: // value for 'states'
 *   },
 * });
 */
export function useSupplierPurchaseOrdersQuery(baseOptions: Apollo.QueryHookOptions<SupplierPurchaseOrdersQuery, SupplierPurchaseOrdersQueryVariables>) {
        return Apollo.useQuery<SupplierPurchaseOrdersQuery, SupplierPurchaseOrdersQueryVariables>(SupplierPurchaseOrdersDocument, baseOptions);
      }
export function useSupplierPurchaseOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierPurchaseOrdersQuery, SupplierPurchaseOrdersQueryVariables>) {
          return Apollo.useLazyQuery<SupplierPurchaseOrdersQuery, SupplierPurchaseOrdersQueryVariables>(SupplierPurchaseOrdersDocument, baseOptions);
        }
export type SupplierPurchaseOrdersQueryHookResult = ReturnType<typeof useSupplierPurchaseOrdersQuery>;
export type SupplierPurchaseOrdersLazyQueryHookResult = ReturnType<typeof useSupplierPurchaseOrdersLazyQuery>;
export type SupplierPurchaseOrdersQueryResult = Apollo.QueryResult<SupplierPurchaseOrdersQuery, SupplierPurchaseOrdersQueryVariables>;
export const SupplierPurchaseOrderDocument = gql`
    query SupplierPurchaseOrder($id: ID!) {
  supplierPurchaseOrder(id: $id) {
    ...SupplierPurchaseOrder
  }
}
    ${SupplierPurchaseOrderFragmentDoc}`;

/**
 * __useSupplierPurchaseOrderQuery__
 *
 * To run a query within a React component, call `useSupplierPurchaseOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierPurchaseOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierPurchaseOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSupplierPurchaseOrderQuery(baseOptions: Apollo.QueryHookOptions<SupplierPurchaseOrderQuery, SupplierPurchaseOrderQueryVariables>) {
        return Apollo.useQuery<SupplierPurchaseOrderQuery, SupplierPurchaseOrderQueryVariables>(SupplierPurchaseOrderDocument, baseOptions);
      }
export function useSupplierPurchaseOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierPurchaseOrderQuery, SupplierPurchaseOrderQueryVariables>) {
          return Apollo.useLazyQuery<SupplierPurchaseOrderQuery, SupplierPurchaseOrderQueryVariables>(SupplierPurchaseOrderDocument, baseOptions);
        }
export type SupplierPurchaseOrderQueryHookResult = ReturnType<typeof useSupplierPurchaseOrderQuery>;
export type SupplierPurchaseOrderLazyQueryHookResult = ReturnType<typeof useSupplierPurchaseOrderLazyQuery>;
export type SupplierPurchaseOrderQueryResult = Apollo.QueryResult<SupplierPurchaseOrderQuery, SupplierPurchaseOrderQueryVariables>;
export const SupplyBnplDocument = gql`
    query SupplyBnpl {
  bnpl
}
    `;

/**
 * __useSupplyBnplQuery__
 *
 * To run a query within a React component, call `useSupplyBnplQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplyBnplQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplyBnplQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupplyBnplQuery(baseOptions?: Apollo.QueryHookOptions<SupplyBnplQuery, SupplyBnplQueryVariables>) {
        return Apollo.useQuery<SupplyBnplQuery, SupplyBnplQueryVariables>(SupplyBnplDocument, baseOptions);
      }
export function useSupplyBnplLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplyBnplQuery, SupplyBnplQueryVariables>) {
          return Apollo.useLazyQuery<SupplyBnplQuery, SupplyBnplQueryVariables>(SupplyBnplDocument, baseOptions);
        }
export type SupplyBnplQueryHookResult = ReturnType<typeof useSupplyBnplQuery>;
export type SupplyBnplLazyQueryHookResult = ReturnType<typeof useSupplyBnplLazyQuery>;
export type SupplyBnplQueryResult = Apollo.QueryResult<SupplyBnplQuery, SupplyBnplQueryVariables>;